import {
  AppBar,
  Toolbar,
  Box,
  IconButton,
  styled,
  Divider,
  useTheme,
  useMediaQuery,
  ThemeProvider,
} from '@mui/material';
import { Menu as BurgerIcon, Close as CloseIcon } from '@mui/icons-material';
import type { FC } from 'react';
import { darkSubTheme } from 'theme';
import WellSpyLogo from 'assets/wellspy-logo.svg?react';
import { Link } from 'react-router-dom';

const NavbarRoot = styled(AppBar)(
  ({ theme: t }) => `
  ${t.breakpoints.up('macbook15')} {
    background-color: ${t.palette.background.paper};
    padding-left: ${t.sizes.sidebarWidth}px;
  }
`
);

const Logo = styled(WellSpyLogo)`
  height: 100%;
`;

const HomeLink = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  object-fit: contain;
  height: 40px;
`;

interface NavbarProps {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const Navbar: FC<NavbarProps> = ({ isDrawerOpen, openDrawer, closeDrawer }) => {
  const defaultTheme = useTheme();
  const showNavbar = useMediaQuery(defaultTheme.breakpoints.down('macbook15'));

  return (
    <ThemeProvider theme={showNavbar ? darkSubTheme : defaultTheme}>
      <Box
        sx={(theme) => ({
          [theme.breakpoints.down('macbook15')]: {
            display: 'flex',
          },
          display: 'none',
        })}
      >
        <NavbarRoot position="fixed" elevation={0}>
          <Toolbar>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 3,
              }}
            >
              {isDrawerOpen ? (
                <IconButton key="drawer_opener" onClick={closeDrawer}>
                  <CloseIcon />
                </IconButton>
              ) : (
                <IconButton key="drawer_opener" onClick={openDrawer}>
                  <BurgerIcon />
                </IconButton>
              )}
              <HomeLink to="/">
                <Logo />
              </HomeLink>
            </Box>
          </Toolbar>
          <Divider />
        </NavbarRoot>
      </Box>
    </ThemeProvider>
  );
};

export default Navbar;
