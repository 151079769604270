import type { RefObject } from 'react';
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

interface useScrolledProps {
  obj: RefObject<HTMLElement>;
  scrollContainerId: string;
}

export function useScrolled({ obj, scrollContainerId }: useScrolledProps) {
  const initialTopOffset = useRef<number | null>(null);
  const [isScrolled, setIsScrolled] = useState(false);

  useLayoutEffect(() => {
    if (obj.current && !initialTopOffset.current) {
      initialTopOffset.current = obj.current.offsetTop;
    }
  }, [initialTopOffset, obj]);

  const onScroll = useCallback(() => {
    setIsScrolled(Number(document.getElementById(scrollContainerId)?.scrollTop) > Number(initialTopOffset.current));
  }, [setIsScrolled, scrollContainerId]);

  useEffect(() => {
    document.getElementById(scrollContainerId)?.addEventListener('scroll', onScroll);
    return () => {
      document.getElementById(scrollContainerId)?.removeEventListener('scroll', onScroll);
    };
  }, [onScroll, scrollContainerId]);

  return isScrolled;
}
