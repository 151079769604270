import { type FC } from 'react';
import { Box, Table, TableRow, TableHead, TableContainer, TableCell, TableBody, Skeleton, Stack } from '@mui/material';

const TagGroupsListSkeleton: FC = () => {
    return (

        <Box sx={{ display: 'flex', flexGrow: 1, flexDirection: 'column', width: '100%' }}>
            <Box sx={{ display: 'flex', flexGrow: 1, width: '100%', position: 'relative' }}>
                <TableContainer component={Box} sx={{ position: 'absolute', top: 0, left: 0, height: '100%' }}>
                    <Table size="medium" stickyHeader sx={{ '& thead th': { backgroundColor: 'white' } }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Visibility</TableCell>
                            <TableCell>Tags count</TableCell>
                            <TableCell>Tags preview</TableCell>
                        </TableRow>
                    </TableHead>
                        <TableBody>
                            {[...Array(15).keys()].map(i => (
                                <TableRow sx={{ paddingY: 2, height: 71, minHeight: 71, }} key={i}>
                                    <TableCell><Skeleton animation="wave" height={30} width={140} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={30} width={30} /></TableCell>
                                    <TableCell><Skeleton animation="wave" height={30} width={30} /></TableCell>
                                    <TableCell>
                                        <Stack direction={"row"} gap={1}>
                                            <Skeleton animation="wave" height={40} width={160} />
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <Box sx={{display: 'flex', justifyContent: 'end', py: 1, px: 2}}><Skeleton animation="wave" height={40} width={400} /></Box>
        </Box>
    );
};

export default TagGroupsListSkeleton;
