import type { Domain } from 'features/domains';
import type { CreativeItemDTO } from '../api/types';
import type { CreativeGroupDTO } from '../api/types';

export type AdType = 'carousel' | 'video' | 'image' | 'html';

export type CreativeStatus = 'to_check' | 'accepted' | 'declined';

export type RootChannel = 'meta' | 'google';

export const RootCreativeChannels = {
  meta: [2, 39, 3],
  google: [55, 1],
};

export const CreativeChannel = {
  2: 'FB News Feed',
  39: 'Instagram',
  3: 'Audience Network',
  55: 'Ad Sense',
  1: 'Google Ads(Admob)',
  // 51: 'Messenger',
  // 6: 'YouTube',
  // 10: 'Twitter',
  // 5: 'UnityAds',
  // 19: 'Vungle',
  // 20: 'AppLovin',
  // 24: 'AdColony',
  // 25: 'Chartboost',
  // 40: 'Pinterest',
  // 42: 'ironSource',
  // 23: 'Yahoo!',
  // 44: 'reddit',
  // 43: 'TikTok',
  // 45: 'TopBuzz',
  // 46: 'Mintegral (Mobvista)',
  // 310: 'Pangle(TikTok Audience Network)',
  // 52: 'Snapchat',
  // 49: 'MoPub',
  // 47: 'Tapjoy',
  // 57: 'Kwai',
  // 27: 'NAVER(네이버)',
  // 26: 'Daum(다음)',
  // 34: 'Nate(네이트)',
  // 28: 'Ameba(アメーバ)',
  // 36: 'Yahoo! Japan',
  // 301: 'Gunosy(グノシー)',
  // 303: 'Zucks',
  // 300: 'SmartNews(スマートニュース)',
  // 304: 'i-mobile',
  // 305: 'AkaNe',
  // 307: 'Nend',
  // 308: 'AMoAd',
};

export type CreativeChannelType = keyof typeof CreativeChannel;
export interface Creative
  extends Omit<CreativeItemDTO, 'domain' | 'status' | 'last_seen' | 'first_seen' | 'created_at' | 'updated_at'> {
  domain: Domain;
  status: CreativeStatus;
  created_at: Date;
  updated_at: Date;
  first_seen: Date;
  last_seen: Date;
}

export interface CreativeGroup
  extends Omit<CreativeGroupDTO, 'creative_items' | 'created_at' | 'first_seen' | 'last_seen' | 'updated_at'> {
  creative_items: Creative[];
  created_at: Date;
  first_seen: Date;
  last_seen: Date;
  updated_at: Date;
}
