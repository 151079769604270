import { useCallback, useMemo } from 'react';
import { isEmpty } from 'lodash-es';
import { Chip } from '@mui/material';
import type { Option, OptionValue } from '../components/common/CheckboxAutocomplete';
import getTruncatedText from '../utils/getTruncatedText';

interface UseDeletableChipsProps {
  changeFn: (value: OptionValue[]) => void;
  data: Option[];
  selectedData: OptionValue[];
}

export function useDeletableChips({ changeFn, data, selectedData }: UseDeletableChipsProps) {
  const removeItem = useCallback(
    (id: OptionValue) => {
      const updatedData = selectedData.filter((selectedId) => selectedId !== id);
      changeFn(updatedData);
    },
    [changeFn, selectedData]
  );

  const chips = useMemo(() => {
    if (isEmpty(data)) return [];

    return data
      .filter(({ value }) => selectedData.includes(value))
      .map(({ value, label }) => (
        <Chip
          key={value}
          label={getTruncatedText({ text: label })}
          onDelete={() => {
            removeItem(value);
          }}
        />
      ));
  }, [data, removeItem, selectedData]);

  return {
    chips,
  };
}
