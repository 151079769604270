import { Box, Typography } from '@mui/material';
import { CompetitorsFlows, ColumnsSettingsProvider } from 'features/competitors-flows';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Titled } from 'react-titled';

const CompetitorsFlowsPage: FC = () => (
  <>
    <Titled title={(title) => `Competitors flows | ${title}`} />
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 3 }}>
      <Box sx={{ mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5">Competitors flows</Typography>
      </Box>
      <ColumnsSettingsProvider>
        <CompetitorsFlows />
      </ColumnsSettingsProvider>
    </Box>
    <Outlet />
  </>
);

export default CompetitorsFlowsPage;
