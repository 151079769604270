import { apiClient } from 'lib/api';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import type { MutationConfig } from 'lib/react-query';

export interface DeleteDomainOptions {
  id: string;
}

export const deleteDomain = async ({ id }: DeleteDomainOptions): Promise<void> => {
  await apiClient.delete(`/domains/${id}`);
};

export interface UseDeleteDomainOptions {
  config?: MutationConfig<typeof deleteDomain>;
}

export const useDeleteDomain = ({ config }: UseDeleteDomainOptions = {}) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    ...config,
    onSuccess: (...args) => {
      void queryClient.invalidateQueries(['domains']);

      if (config?.onSuccess) {
        config.onSuccess(...args);
      }
    },
    mutationFn: deleteDomain,
  });

  return mutation;
};
