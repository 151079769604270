import type { UseSuspenseQueryOptions } from '@suspensive/react-query';
import { useSuspenseQuery } from '@suspensive/react-query';
import type { QueryKey } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { DomainDTO } from './types';
import getDomainFromDTO from './getDomainFromDTO';
import type { Domain } from '../types';

export const getDomains = async (): Promise<DomainDTO[]> => {
  const res = await apiClient.get<DomainDTO[]>(`/domains`);
  return res.data;
};

export const useDomains = <TData = Domain[]>({
  config,
}: { config?: Omit<UseSuspenseQueryOptions<DomainDTO[], unknown, TData>, 'enabled'> } = {}) =>
  useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    select: (data) => data.map((d) => getDomainFromDTO(d)) as TData,
    ...config,
    queryFn: () => getDomains(),
    queryKey: ['domains'] as QueryKey,
  });
