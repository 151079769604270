import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@mui/material';
import { DeleteOutline as DeleteIcon } from '@mui/icons-material';
import type { SyntheticEvent } from 'react';
import { useState, type FC, useCallback } from 'react';
import { useDeleteTag } from '../../api';
import { LoadingButton } from '@mui/lab';
import type { TagEntry } from '../../types';
import { useModal } from 'hooks';
import { isAxiosError } from 'axios';

interface TagDeleteProps {
  groupId: number;
  tag: TagEntry;
}

const TagDelete: FC<TagDeleteProps> = ({ groupId, tag }) => {
  const { tag_id, tag_name } = tag;

  const actionDialog = useModal();
  const [formError, setFormError] = useState<string | null>(null);
  const deleteTag = useDeleteTag(groupId, {
    config: {
      onSuccess: () => {
        onClose();
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setFormError((e.response.data as { message: string }).message);
        }
      },
    },
  });

  const onDelete = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      deleteTag.mutate({ tag_id });
    },
    [tag_id, deleteTag]
  );

  const onClose = useCallback(() => {
    actionDialog.close();
    setFormError(null);
  }, [actionDialog, setFormError]);

  return (
    <>
      <Button size="small" variant="text" color="error" onClick={actionDialog.open} sx={{ minWidth: 34 }}>
        <DeleteIcon />
      </Button>
      <Dialog fullWidth maxWidth="xs" open={actionDialog.isOpen}>
        <DialogTitle>Delete tag</DialogTitle>
        <DialogContent>
          <DialogContentText color={'black'}>
            Delete{' '}
            <Typography component={'span'} fontWeight={'bold'}>
              {tag_name}
            </Typography>{' '}
            tag?
            {formError && (
              <Typography variant="caption" color="red">
                {formError}
              </Typography>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deleteTag.isLoading} onClick={onClose} color="info">
            Cancel
          </Button>
          <LoadingButton loading={deleteTag.isLoading} onClick={onDelete} variant="outlined" color="error">
            Delete
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TagDelete;
