import type { QueryKey, UseQueryOptions } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import queryString from 'query-string';
import type { FlowsAutocompleteEntry, GetFlowsAutocomplete } from './types';
import { useQuery } from '@tanstack/react-query';

interface FilteringFields {
  domain_id: number[];
}

interface GetFlowsAutocompleteOptions {
  params: Partial<FilteringFields>;
}

export const getFlowsAutocomplete = async (
  { params }: GetFlowsAutocompleteOptions,
  signal?: AbortSignal
): Promise<FlowsAutocompleteEntry[]> => {
  const res = await apiClient.get<GetFlowsAutocomplete>(`/flows/autocomplete`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });

  return res.data.data;
};

interface UseFlowsAutocompleteOptions<TData> {
  config?: UseQueryOptions<FlowsAutocompleteEntry[], unknown, TData>;
  params: GetFlowsAutocompleteOptions['params'];
}

export const useFlowsAutocomplete = <TData = FlowsAutocompleteEntry[]>({
  config,
  params,
}: UseFlowsAutocompleteOptions<TData>) => {
  const { domain_id } = params;
  return useQuery({
    staleTime: 60 * 60 * 1000,
    suspense: true,
    ...config,
    queryFn: () => getFlowsAutocomplete({ params }),
    queryKey: ['flows/autocomplete', domain_id] as QueryKey,
  });
};
