/* eslint-disable @typescript-eslint/no-unsafe-argument */
import {Button, Dialog, DialogTitle, DialogContentText, DialogContent, DialogActions} from '@mui/material';
import { useModal } from 'hooks';
import { useAuth } from 'lib/auth/AuthContext';
import hotjarIdentify from 'lib/hotjarIdentify';
import { useEffect } from 'react';
import { persistData, getPersistedData } from 'utils/persist';

const HotjarDialog = () => {
    const isConsent = getPersistedData("hotjat_consent");
    const { isOpen, close } = useModal(!isConsent);
    
    const { user } = useAuth();

    useEffect(() => {
        if(user && isConsent === "agree")
            hotjarIdentify(user.getUsername(), user.getSignInUserSession()?.getIdToken()?.decodePayload().email);
    }, [])

    const handleDisagree = () => {
        persistData("hotjat_consent", "disagree");
        close();
    }

    const handleAgree = () => {
      if( user ) {
        hotjarIdentify(user.getUsername(), user.getSignInUserSession()?.getIdToken()?.decodePayload().email);
        persistData("hotjat_consent", "agree");
        close();
      }
    }

    return (
        <Dialog open={isOpen}>
            <DialogTitle>
                Consent
            </DialogTitle>
            <DialogContent>
                <DialogContentText color={'black'}>
                    I agree that my actions will be recorded along with my email to improve WellSpy as a product.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDisagree} color="primary">Disagree</Button>
                <Button onClick={handleAgree} variant="contained" color="primary">Agree</Button>
            </DialogActions>
        </Dialog>
    )
};

export default HotjarDialog;
