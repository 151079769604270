import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { TagGroupEntry } from '../types';

interface UpdateTagGroupPayload {
  group_id: number,
  group_name?: string,
  is_visible?: boolean,
}

export const updateTagGroup = async ({group_id, ...data}: UpdateTagGroupPayload): Promise<TagGroupEntry> => {
  const res = await apiClient.patch<TagGroupEntry>(`/tag_groups/${group_id}`, data);
  return res.data;
};

interface UseUpdateTagGroupOptions {
  config?: MutationConfig<typeof updateTagGroup>;
}

export const useUpdateTagGroup = (groupId: number, { config }: UseUpdateTagGroupOptions = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(['tag_groups']);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    onError: async (...args) => {
      if (config?.onError) {
        await config.onError(...args);
      }
    },
    mutationFn: (params) => updateTagGroup(params),
  });

  return {
    ...mutation,
    mutate: (params: Pick<UpdateTagGroupPayload, 'group_name' | 'is_visible'>) => {
      mutation.mutate({ group_id: groupId, ...params });
    },
  };
};
