import { CssBaseline, ThemeProvider, styled, Box } from '@mui/material';
import { useModal } from 'hooks';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';
import theme from 'theme';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import HotjarDialog from 'components/HotjarDialog';

const Base = styled(Box)`
  display: flex;
  min-height: 100vh;
`;

const Main = styled('main')`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ theme: t }) => t.breakpoints.down('macbook15')} {
    &::before {
      content: '';
      display: block;
      ${({ theme: t }) => t.mixins.toolbar};
    }
  }
`;

interface LayoutProps {
  children: ReactNode;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  const { isOpen: isDrawerOpen, open: openDrawer, close: closeDrawer } = useModal();
  const location = useLocation();

  useEffect(() => {
    closeDrawer();
  }, [closeDrawer, location.pathname]);

  return (
    <ThemeProvider theme={theme}>
      <HotjarDialog />
      <CssBaseline />
      <Base>
        <Navbar isDrawerOpen={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
        <Sidebar isShown={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
        <Main>{children}</Main>
      </Base>
    </ThemeProvider>
  );
};
export default Layout;
