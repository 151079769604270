/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material';
import { useState, type FC, useCallback, useEffect } from 'react';
import { ErrorOutline } from '@mui/icons-material';
import { isAxiosError } from 'axios';
import { useUpdateTagGroup } from '../../api';

interface TagGroupNameProps {
  groupName: string,
  groupId: number
};

const TagGroupName: FC<TagGroupNameProps> = ({ groupName, groupId }) => {
  const [name, setName] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);
  const handleTagGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setFormError('');
  };

  useEffect(() => {
    setName(groupName);
  }, [groupName])

  const updateTagGroup = useUpdateTagGroup(groupId, {
    config: {
      onError: (e) => {
        if( isAxiosError(e) && e.response ) {
          setFormError((e.response.data as { message: string }).message);
        }
      },
    }
  });

  const onChangeName = useCallback(() => {
    if (groupName === name) return;
    setFormError("");
    updateTagGroup.mutate({ group_name: name.trim() });
  }, [name, groupName, groupId, updateTagGroup])

  return (
    <TextField
      required
      fullWidth
      autoFocus
      label="Tag group name"
      name="groupName"
      onKeyUpCapture={e => { e.key === 'Enter' && onChangeName(); }}
      onChange={handleTagGroupNameChange}
      onBlurCapture={onChangeName}
      value={name}
      error={Boolean(formError)}
      helperText={formError && (<Typography fontSize={14} marginTop={-2}>{formError}</Typography>)}
      disabled={updateTagGroup.isLoading}
      InputProps={{
        sx: { mb: 2 },
        endAdornment: (
          updateTagGroup.isLoading
            ? <CircularProgress size={20} color="success" /> :
            updateTagGroup.isError && formError?.length
              ? <ErrorOutline color="error" sx={{ width: 22 }} /> : null)
      }}
      inputProps={{ style: { fontSize: 20 }, maxLength: 120 }}
      onBlur={e => { !e.target.value.length && setFormError("Name can’t be empty"); }}
    />
  )
};

export default TagGroupName;
