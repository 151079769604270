import { DeleteOutline, MoreVert } from '@mui/icons-material';
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { useAnchorMenu } from 'hooks';
import type { FC } from 'react';
import { useDeleteDomain } from '../api';
import type { Domain } from '../types';

interface DomainRowProps {
  domain: Domain;
}

const DomainRow: FC<DomainRowProps> = ({ domain }) => {
  const menu = useAnchorMenu();
  const deleteDomain = useDeleteDomain({
    config: {
      onSuccess: () => {
        menu.handleCloseMenu();
      },
    },
  });

  const handleDeleteDomainClick = () => {
     
    if (window.confirm(`Are you sure you want to delete domain? This action can't be undone`)) {
      deleteDomain.mutate({ id: String(domain.id) });
    }
  };

  return (
    <TableRow>
      <TableCell>{domain.id}</TableCell>
      <TableCell>
        <Typography variant="body1">{domain.domain}</Typography>
      </TableCell>
      <TableCell>
        <Typography variant="caption">{format(domain.created_at, 'dd.MM.yyyy HH:mm')}</Typography>
      </TableCell>
      <TableCell sx={{ textAlign: 'right' }}>
        <IconButton onClick={menu.handleOpenMenu}>
          <MoreVert />
        </IconButton>

        <Menu
          anchorEl={menu.anchor}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menu.isOpen}
          onClose={menu.handleCloseMenu}
        >
          <MenuItem onClick={handleDeleteDomainClick} color="error">
            <ListItemIcon>
              {deleteDomain.isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                <DeleteOutline color="error" />
              )}
            </ListItemIcon>
            <ListItemText>Delete domain</ListItemText>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  );
};

export default DomainRow;
