import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { TagEntry } from '../types';

interface CreateTagPayload {
  group_id: number;
  tag_name: string;
}

export const createTag = async ({group_id, ...data}: CreateTagPayload): Promise<TagEntry> => {
  const res = await apiClient.post<TagEntry>(`/tag_groups/${group_id}/tags`, data);
  return res.data;
};

interface UseCreateTagOptions {
  config?: MutationConfig<typeof createTag>;
}

export const useCreateTag = (groupId: number, { config }: UseCreateTagOptions) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(['tag_groups']);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: createTag,
  });

  return {
    ...mutation,
    mutate: ({ tag_name }: Pick<CreateTagPayload, 'tag_name'>) => {
      mutation.mutate({ group_id: groupId, tag_name });
    },
  };
};
