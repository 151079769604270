/* eslint-disable @typescript-eslint/no-explicit-any */

import type { QueryClientConfig, UseMutationOptions, UseQueryOptions } from '@tanstack/react-query';
import { QueryClient } from '@tanstack/react-query';
import type { AxiosError } from 'axios';

export const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      suspense: true,
      cacheTime: 1000 * 60 * 60 * 24, // 24 hours
      refetchOnWindowFocus: import.meta.env.PROD,
    },
  },
};

export const queryClient = new QueryClient(queryClientConfig);

type Fn = (...args: any[]) => any;

export type ExtractFnReturnType<FnType extends Fn> = Awaited<ReturnType<FnType>>;

export type QueryConfig<QueryFnType extends Fn> = Omit<
  UseQueryOptions<ExtractFnReturnType<QueryFnType>>,
  'queryKey' | 'queryFn'
>;

type GetTVariables<T> = T extends (...args: infer R) => any ? (R extends [infer TVar, ...any] ? TVar : never) : never;

export type MutationConfig<MutationFnType extends Fn> = UseMutationOptions<
  ExtractFnReturnType<Awaited<ReturnType<MutationFnType>>>,
  AxiosError,
  GetTVariables<MutationFnType>
>;
