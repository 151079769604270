import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import type { UsePaginationResult, UseSortingResult } from 'hooks';
import type { FC} from 'react';
import { useEffect } from 'react';
import { useDomains } from '../api';
import type { Domain } from '../types';
import DomainRow from './DomainRow';

interface DomainsListProps {
  sorting: UseSortingResult<Domain>;
  pagination: UsePaginationResult;
  setTotal: (total: number) => void;
  // search?: string;
}

const DomainsList: FC<DomainsListProps> = ({ sorting, pagination, setTotal }) => {
  const { field, order, createSortHandler } = sorting;
  const { page, perPage, handlePageChange, handleRowsPerPageChange } = pagination;
  const { data: domains } = useDomains();

  useEffect(() => {
    setTotal(domains.length);
  }, [setTotal, domains]);

  return (
    <>
      <TableContainer sx={{ height: 0, flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                ID
              </TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                <TableSortLabel
                  active={field === 'domain'}
                  direction={field === 'domain' ? order : 'asc'}
                  onClick={createSortHandler('domain')}
                >
                  Domain
                </TableSortLabel>
              </TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                Created at
              </TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }} />
            </TableRow>
          </TableHead>
          <TableBody>{domains.map((domain) => <DomainRow key={`domain_${domain.id}`} domain={domain} />)}</TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={domains.length || 0}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={[50, 100, 150]}
      />
    </>
  );
};

export default DomainsList;
