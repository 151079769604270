import type { ThemeOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Theme {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface ThemeOptions {
    sizes: {
      sidebarWidth: number;
    };
  }

  interface Palette {
    dark: Palette['primary'];
  }
  interface PaletteOptions {
    dark: PaletteOptions['primary'];
  }

  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    macbook15: true;
  }
}

export const themeOptions: ThemeOptions = {
  sizes: {
    sidebarWidth: 248,
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      macbook15: 1440,
      xl: 1536,
    },
  },
  palette: {
    mode: 'light',
    dark: {
      main: 'rgba(17, 24, 39, 1)',
    },
    background: {
      default: '#f9fafc',
    },
  },
};

const theme = createTheme(themeOptions);

export const darkSubTheme = createTheme({
  ...themeOptions,
  palette: {
    mode: 'dark',
    dark: {
      main: 'rgba(17, 24, 39, 1)',
    },
    background: {
      paper: 'rgba(17, 24, 39, 1)',
    },
  },
});

export default theme;
