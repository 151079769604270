import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import type { FC } from 'react';
import { useState } from 'react';
import { useCreateTagGroup } from '../api/createTagGroup';
import { useModal } from 'hooks';
import { isAxiosError } from 'axios';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';
import type { TagGroupEntry } from '../types';

const AddTagGroupDialog: FC = () => {
  const [, setTagGroupIdDetails] = useQueryParam('group_id', withDefault(NumberParam, null));
  const actionDialog = useModal();
  const [name, setName] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);
  const handleTagGroupNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
    setFormError('');
  };

  const handleClose = () => {
    setFormError('');
    setName('');
    setFormError(null);
    actionDialog.close();
  };

  const createTagGroup = useCreateTagGroup({
    config: {
      onSuccess: ({ group_id }: TagGroupEntry) => {
        setTagGroupIdDetails(group_id);
        handleClose();
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setFormError((e.response.data as { message: string }).message);
        }
      },
    },
  });

  const handleCreate = () => {
    createTagGroup.mutate({ group_name: name.trim() });
  };

  return (
    <>
      <Button onClick={actionDialog.open} variant="contained" endIcon={<AddIcon />}>
        Add group
      </Button>
      <Dialog maxWidth="xs" onClose={handleClose} open={actionDialog.isOpen} disableRestoreFocus>
        <Stack sx={{ p: 2, width: 350 }} spacing={4}>
          <Box>
            <Typography variant="h5">Add Tag group</Typography>
          </Box>
          <Box>
            <TextField
              required
              autoFocus
              label="Tag group"
              name="groupName"
              fullWidth
              onChange={handleTagGroupNameChange}
              value={name}
              error={Boolean(formError)}
              helperText={formError}
              maxRows={1}
              inputProps={{ maxLength: 120 }}
              onBlur={(e) => {
                !e.target.value.length && setFormError('Field is required');
              }}
            />
          </Box>
          <Stack sx={{ mt: 4 }} spacing={2}>
            <LoadingButton
              size="large"
              variant="contained"
              onClick={handleCreate}
              fullWidth
              loading={createTagGroup.isLoading}
              disabled={!name.length}
            >
              Add
            </LoadingButton>
            <Button size="large" variant="outlined" onClick={handleClose} color="primary" fullWidth>
              Cancel
            </Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
};

export default AddTagGroupDialog;
