import ClearIcon from '@mui/icons-material/Clear';
import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, Grid, LinearProgress, TablePagination } from '@mui/material';
import PeriodFilter from 'components/common/PeriodFilter';
import { useDebouncedQueryParam, usePagination } from 'hooks';
import type { FC } from 'react';
import { Suspense, useCallback, useState } from 'react';
import { BooleanParam, NumericArrayParam, StringParam, useQueryParam, withDefault } from 'use-query-params';
import { DomainAutocomplete } from '../../domains';
import { useFlowSortingParam } from '../hooks/useFlowSortingParam';
import type { CompetitorsFlowsOrderValue } from '../types';
import CompetitorsFlowsColumnsSettings from './CompetitorsFlowsColumnsSettings';
import CompetitorsFlowsList from './CompetitorsFlowsList';

export const ROWS_PERPAGE_OPTIONS = [15, 25, 50];
export const DEFAULT_PER_PAGE = 25;
export const DEFAULT_IMPRESSIONS_PERIOD = '30';

const DomainsParam = withDefault(NumericArrayParam, [] as number[]);
const PeriodParam = withDefault(StringParam, DEFAULT_IMPRESSIONS_PERIOD);
const SortDirectionParam = withDefault(BooleanParam, true);

const CompetitorsFlows: FC = () => {
  const [orderBy, setOrderBy] = useFlowSortingParam();
  const [total, setTotal] = useState(0);
  const [desc, setDesc] = useQueryParam('desc', SortDirectionParam);
  const {
    param: domains,
    actualValue: actualDomains,
    handleChange: setDomains,
  } = useDebouncedQueryParam<typeof DomainsParam, number[]>({ name: 'domains', defaultValue: DomainsParam });
  const [period, setPeriod] = useQueryParam('period', PeriodParam);

  const handleDomainChange = useCallback(
    (dom: number[]) => {
      setDomains(dom);
    },
    [setDomains]
  );

  const handleImpressionsPeriodChange = useCallback(
    (event: SelectChangeEvent) => {
      setPeriod(event.target.value);
    },
    [setPeriod]
  );

  const { page, perPage, handlePageChange, handleRowsPerPageChange, resetPagination } = usePagination(
    [orderBy, desc, domains, period],
    DEFAULT_PER_PAGE
  );

  const handleResetFilters = useCallback(() => {
    setDomains([]);
    setPeriod(DEFAULT_IMPRESSIONS_PERIOD);
  }, [setDomains, setPeriod]);

  const handleResetFiltersWithPagination = useCallback(() => {
    handleResetFilters();
    resetPagination();
  }, [handleResetFilters, resetPagination]);

  const handleToggleSort = useCallback(
    (id: string) => {
      if (id === orderBy) setDesc((prevState) => !prevState);
      else {
        setOrderBy(id);
        setDesc(true);
      }
    },
    [orderBy, setOrderBy, setDesc]
  );

  const activeFilters = domains.length || period !== DEFAULT_IMPRESSIONS_PERIOD;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Grid container alignItems="center" spacing={2} sx={{ mb: 3 }}>
          <Grid item xs={2} sx={{ minWidth: 220 }}>
            <DomainAutocomplete
              value={actualDomains}
              InputProps={{
                label: 'Domains',
              }}
              onChange={handleDomainChange}
            />
          </Grid>
          <Grid item xs={2}>
            <PeriodFilter
              inputLabel="Impressions period"
              value={period}
              onChange={handleImpressionsPeriodChange}
              fullWidth
            />
          </Grid>
          {activeFilters && (
            <Grid item xs={2}>
              <Button variant="text" onClick={handleResetFilters} sx={{ ml: 2 }}>
                <ClearIcon sx={{ mr: 1 }} />
                <span>Reset Filters</span>
              </Button>
            </Grid>
          )}
        </Grid>

        <CompetitorsFlowsColumnsSettings />
      </Box>
      <Box>
        <Suspense fallback={<LinearProgress />}>
          {!!orderBy && (
            <CompetitorsFlowsList
              key="competitors-flows"
              limit={perPage}
              offset={page * perPage}
              setTotal={setTotal}
              reset={handleResetFiltersWithPagination}
              domains={domains as number[] | undefined}
              period={period}
              orderBy={orderBy as CompetitorsFlowsOrderValue}
              desc={desc}
              handleToggleSort={handleToggleSort}
            />
          )}
        </Suspense>
      </Box>

      <TablePagination
        labelRowsPerPage="Domains per page:"
        component="div"
        count={total}
        page={page}
        onPageChange={handlePageChange}
        rowsPerPage={perPage}
        onRowsPerPageChange={handleRowsPerPageChange}
        rowsPerPageOptions={ROWS_PERPAGE_OPTIONS}
      />
    </Box>
  );
};

export default CompetitorsFlows;
