import type { FC, SyntheticEvent} from "react";
import { useCallback, useEffect, useState } from "react";
import { useUpdateTagGroup } from "../api/updateTagGroup";
import { CircularProgress, FormControlLabel, FormGroup, Switch, Typography } from "@mui/material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

interface SwitchVisibilityOptions {
    groupId: number,
    checked: boolean,
    label?: string
}

const SwitchVisibility: FC<SwitchVisibilityOptions> = ({ groupId, checked, label = '' }) => {
    const [isChecked, setIsChecked] = useState(checked);
    const updateTagGroup = useUpdateTagGroup(groupId, {
      config: {
        onError: () => {
          setIsChecked(prev => !prev);
        }
    }
    });

    useEffect(() => {
      setIsChecked(checked);
    }, [checked])

    const onClick = useCallback((e: SyntheticEvent) => {
        e.stopPropagation();
        setIsChecked(prev => !prev);
        updateTagGroup.mutate({ is_visible: !isChecked });
    }, [groupId, isChecked, updateTagGroup]);

    return (
        <FormGroup sx={{position: 'relative'}}>
            <FormControlLabel
                control={<Switch disabled={updateTagGroup.isLoading} checked={isChecked} onClick={onClick} />}
                label={<Typography sx={{fontSize: 14}}>{label}</Typography>}
            />
            {updateTagGroup.isError && <ErrorOutlineIcon color="error" sx={{position: 'absolute', width: 22, right: -16, top: 7, bottom: 0}} />}
            {updateTagGroup.isLoading && <CircularProgress size={20} color="success" sx={{position: 'absolute', right: -15, top: 8, bottom: 0}} />}
        </FormGroup>
    )
}

export default SwitchVisibility;
