import { isAxiosError } from 'axios';
import PermissionsLackSnackbar from 'components/PermissionsLackSnackbar';
import { enqueueSnackbar } from 'notistack';
import { createElement } from 'react';

export default function handleAPIPermissionError(error: unknown) {
  if (isAxiosError(error)) {
    if (error.response?.status === 403) {
      enqueueSnackbar(createElement(PermissionsLackSnackbar), {
        key: '403-error',
        variant: 'error',
        autoHideDuration: 5000,
      });
      return;
    }
  }
  enqueueSnackbar(`Unexpected error. We can't help with this issue now.`, {
    key: '500-error',
    variant: 'error',
    autoHideDuration: 5000,
  });
}
