import { MoreVert } from '@mui/icons-material';
import {
  IconButton,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import type { FC } from 'react';
import getSkeletonArray from 'utils/getSkeletonArray';

const DomainsListSkeleton: FC = () => {
  const domains = getSkeletonArray(10);

  return (
    <>
      <TableContainer sx={{ height: 0, flexGrow: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                ID
              </TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                Domain
              </TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }}>
                Created at
              </TableCell>
              <TableCell component="th" sx={{ backgroundColor: 'white' }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {domains.map((domain) => (
              <TableRow key={domain.id}>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell>
                  <Typography>
                    <Skeleton />
                  </Typography>
                </TableCell>
                <TableCell>
                  <Skeleton />
                </TableCell>
                <TableCell sx={{ textAlign: 'right' }}>
                  <IconButton>
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={domains.length || 0}
        page={Infinity}
        onPageChange={() => {
          /* just mock */
        }}
        rowsPerPage={Infinity}
        onRowsPerPageChange={() => {
          /* just mock */
        }}
        rowsPerPageOptions={[50, 100, 150]}
      />
    </>
  );
};

export default DomainsListSkeleton;
