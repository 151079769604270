import type { APIUsageEntry } from '../types';
import type { APIUsageEntryDTO } from './types';

export default function getAPIUsageEntryFromDTO(dto: APIUsageEntryDTO): APIUsageEntry {
  const domain: APIUsageEntry = {
    ...dto,
    created_at: new Date(dto.created_at),
    updated_at: new Date(dto.updated_at),
  };

  return domain;
}
