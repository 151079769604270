import { type Theme, styled } from '@mui/material';
import type { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const Image = styled('img')(`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
`);

const SwiperContainer = styled(Swiper)(
  ({ theme }: { theme: Theme }) => `
  --swiper-navigation-color: ${theme.palette.primary.main};
  --swiper-pagination-color: ${theme.palette.primary.main};
  --swiper-pagination-bullet-size: ${theme.spacing(1.5)};
`
);

interface CarouselProps {
  images: string[];
}

const Carousel: FC<CarouselProps> = ({ images }) => (
  <SwiperContainer slidesPerView={1} modules={[Navigation, Pagination]} navigation pagination={{ clickable: true }}>
    {images.map((src) => (
      <SwiperSlide key={src}>
        <Image src={src} alt={src} />
      </SwiperSlide>
    ))}
  </SwiperContainer>
);

export default Carousel;
