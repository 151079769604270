import { Link, Typography } from '@mui/material';

const PermissionsLackSnackbar = () => (
  <Typography color="inherit">
    {`You don't have permission to perform this action. Please, request Viewer permissions in `}
    <Link href="https://form.asana.com/?k=5ePx5AYR2Duzp_qgW88ivQ&d=1125283907112431/" target="_blank" color="inherit">
      Help Desk
    </Link>
    .
  </Typography>
);

export default PermissionsLackSnackbar;
