import { LoadingButton } from '@mui/lab';
import { /* Autocomplete, */ Box, Button, Dialog, Stack, TextField, Typography } from '@mui/material';
import { isAxiosError } from 'axios';
import type { FC } from 'react';
import { useState } from 'react';
import { useCreateDomain } from '../api/createDomain';

interface AddDomainDialogProps {
  isOpen: boolean;
  onClose: (action?: 'declined') => void;
}

// const projectsList = [
//   {
//     id: 1,
//     name: 'Yoga Go',
//   },
//   {
//     id: 2,
//     name: 'Running',
//   },
//   {
//     id: 3,
//     name: 'Muscle Booster',
//   },
//   {
//     id: 4,
//     name: 'Omo',
//   },
//   {
//     id: 5,
//     name: 'Fast Easy',
//   },
// ];

const AddDomainDialog: FC<AddDomainDialogProps> = ({ isOpen, onClose }) => {
  const [domain, setDomain] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);
  const handleDomainChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDomain(event.target.value);
  };

  const handleClose = () => {
    setDomain('');
    setFormError(null);
    onClose();
  };

  const createDomain = useCreateDomain({
    config: {
      onSuccess: () => {
        handleClose();
      },
    },
  });

  const handleCreate = () => {
    try {
      createDomain.mutate({ domain });
    } catch (e) {
      if (isAxiosError(e)) {
        if (e.response) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          setFormError(e.response.data.message as string);
        }
      }
    }
  };

  return (
    <Dialog maxWidth="xs" onClose={handleClose} open={isOpen}>
      <Stack sx={{ p: 2, width: 350 }} spacing={4}>
        <Box>
          <Typography variant="h5">Add domain</Typography>
          <Typography variant="body2">Add a domain to scrap creatives from</Typography>
        </Box>
        <Box>
          <TextField
            label="Domain"
            name="domain"
            fullWidth
            onChange={handleDomainChange}
            value={domain}
            error={Boolean(formError)}
            helperText={formError}
          />
        </Box>
        {/* <Box>
          <Autocomplete
            multiple
            limitTags={2}
            id="related-projects"
            options={projectsList}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} label="Related projects" placeholder="Projects" />}
            fullWidth
          />
        </Box> */}
        <Stack sx={{ mt: 4 }} spacing={2}>
          <LoadingButton
            size="large"
            variant="contained"
            onClick={handleCreate}
            fullWidth
            loading={createDomain.isLoading}
          >
            Add
          </LoadingButton>
          <Button size="large" variant="outlined" onClick={handleClose} color="primary" fullWidth>
            Cancel
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default AddDomainDialog;
