import { useSuspenseQuery } from '@suspensive/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { ListResponse, PaginationParams, SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { CreativeGroupDTO } from './types';
import getCreativeGroupFromDTO from './getCreativeGroupFromDTO.ts';
import type { CreativeGroup, CreativeStatus } from '../types';

interface FilteringFields {
  status: CreativeStatus[];
  channel: number[];
  ad_type: string[];
  domain_id: number[];
  flow_id: number[];
  period: string;
  first_seen: boolean;
}

interface GetCreativesOptions {
  params: PaginationParams & Partial<SortingParams> & Partial<FilteringFields>;
}

export const getCreatives = async (
  { params }: GetCreativesOptions,
  signal?: AbortSignal
): Promise<ListResponse<CreativeGroup>> => {
  const res = await apiClient.get<ListResponse<CreativeGroupDTO>>(`/creatives`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return {
    count: res.data.count,
    limit: res.data.limit,
    offset: res.data.offset,
    data: res.data.data.map((creativeGroup) => getCreativeGroupFromDTO(creativeGroup)),
  };
};

interface UseProjectCreativesOptions {
  config?: QueryConfig<typeof getCreatives>;
  params: GetCreativesOptions['params'];
}

export const useCreatives = ({ config, params }: UseProjectCreativesOptions) => {
  const { order_by, desc, offset, limit, period, domain_id, flow_id, channel, ad_type, status, first_seen } = params;

  return useSuspenseQuery({
    staleTime: 60 * 60 * 1000,
    ...config,
    queryFn: ({ signal }) => getCreatives({ params }, signal),
    queryKey: [
      'creatives',
      offset,
      limit,
      order_by,
      desc,
      period,
      domain_id,
      channel,
      ad_type,
      status,
      first_seen,
      flow_id,
    ] as QueryKey,
  });
};
