import type { FC, ReactNode } from 'react';
import { Titled } from 'react-titled';
import { Box, Typography } from '@mui/material';

interface StickyLayoutProps {
  children: ReactNode;
  pageTitle: string;
  id: string;
}
const StickyLayout: FC<StickyLayoutProps> = ({ children, pageTitle, id }: StickyLayoutProps) => {
  return (
    <>
      <Titled title={(title) => `${pageTitle} | ${title}`} />
      <Box sx={{ display: 'flex', flexGrow: 1, position: 'relative' }}>
        <Box id={id} sx={{ position: 'absolute', height: '100%', left: 0, top: 0, right: 0, overflow: 'scroll' }}>
          <Typography variant="h5" mt={3} ml={3} mb={2}>
            {pageTitle}
          </Typography>
          {children}
        </Box>
      </Box>
    </>
  );
};

export default StickyLayout;
