 
import { styled } from '@mui/material/styles';
import { TableRow, TableCell, Link, Typography, type Theme } from '@mui/material';
import type { ReactNode } from 'react';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  boxShadow: `inset 0 1px 0 0 ${theme.palette.grey[200]}`,
  td: {
    padding: `${theme.spacing(0.8)} ${theme.spacing(2)}`,
    borderBottomColor: theme.palette.grey[200],
  },

  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.grey[100],
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },

  '&:hover td': {
    backgroundColor: theme.palette.grey[200],
  },
}));

export const StyledShowMoreRow = styled(TableRow)({
  border: 0,
  backgroundColor: 'white',
});

export const StyledTableCell = styled(TableCell)(() => ({
  wordBreak: 'break-all',
}));

export const StyledLink = styled(Link)({
  '&:hover': {
    textDecoration: 'none',
  },
});

export const StyledShowMore = styled(TableCell)(({ theme }) => ({
  position: 'sticky',
  zIndex: 3,
  top: 0,
  left: 162,
  borderBottomColor: `${theme.palette.grey[200]}`,
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
}));

export const StyledStickyTableCell = styled(TableCell)(({ theme }: { theme: Theme }) => ({
    position: 'sticky',
    zIndex: 3,
    top: 0,
    left: 0,
    boxShadow: `inset -1px 0 0 0 ${theme.palette.grey[200]}`,
}));

export const StyledStickyHeadTableCell = styled(TableCell)({
  position: 'sticky',
  zIndex: 3,
  top: 0,
  left: 0,
  backgroundColor: 'white',
});

export const TableCellItem = ({ children, colSpan }: { children: ReactNode; colSpan?: number }) => (
  <TableCell align="center" colSpan={colSpan} sx={{ backgroundColor: 'white' }}>
    <Typography fontWeight="bold">{children}</Typography>
  </TableCell>
);
