import type { CognitoUser } from '@aws-amplify/auth';
import { createContext, useContext } from 'react';

export type AuthStateType = 'unauthenticated' | 'authenticated' | 'inProgress';

export interface AuthStateValue {
  user: CognitoUser | null;
  state: AuthStateType;
}

export type AuthContextType = AuthStateValue

export const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function useAuth() {
  return useContext(AuthContext);
}
