import type { SelectChangeEvent } from '@mui/material';
import {
  Typography,
  Box,
  Paper,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  InputAdornment,
  Button,
  Autocomplete,
} from '@mui/material';
import type { FC } from 'react';
import { Suspense, useState } from 'react';
import { Add as AddIcon, Search as SearchIcon } from '@mui/icons-material';
import { useModal, usePagination, useQueryState, useSearch, useSorting } from 'hooks';
import { Titled } from 'react-titled';
import type { Domain } from 'features/domains';
import { AddDomainDialog, DomainsList, DomainsListSkeleton } from 'features/domains';

const projectsList = [
  {
    id: 1,
    name: 'Yoga Go',
  },
  {
    id: 2,
    name: 'Running',
  },
  {
    id: 3,
    name: 'Muscle Booster',
  },
  {
    id: 4,
    name: 'Omo',
  },
  {
    id: 5,
    name: 'Fast Easy',
  },
];

const DomainsPage: FC = () => {
  const addModal = useModal();
  const { search, searchFieldValue, handleSearchFieldChange } = useSearch();
  const [status, setStatus] = useQueryState('status');
  const sorting = useSorting<Domain>('domain');
  const pagination = usePagination([search, sorting.field, sorting.order], 150);
  const [total, setTotal] = useState<number>(0);

  const handleStatusChange = (event: SelectChangeEvent) => {
    setStatus(event.target.value);
  };

  return (
    <>
      <Titled title={(title) => `Domains | ${title}`} />
      <AddDomainDialog onClose={addModal.close} isOpen={addModal.isOpen} />
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">Domains</Typography>
          <Button variant="contained" color="primary" endIcon={<AddIcon />} onClick={addModal.open}>
            Add domain
          </Button>
        </Box>
        <Paper sx={{ mt: 3.5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ p: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
            <TextField
              label="Search"
              placeholder="Identifier"
              onChange={handleSearchFieldChange}
              value={searchFieldValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <FormControl>
              <InputLabel>Status</InputLabel>
              <Select
                value={status}
                onChange={handleStatusChange}
                sx={{ width: 150 }}
                placeholder="Status"
                label="Status"
              >
                <MenuItem value={undefined}>All</MenuItem>
                <MenuItem value="active">Active</MenuItem>
                <MenuItem value="disbled">Disabled</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              multiple
              limitTags={2}
              id="related-projects"
              options={projectsList}
              sx={{ minWidth: 300 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => <TextField {...params} label="Projects" placeholder="Projects" />}
            />
            <Box sx={{ ml: 'auto' }}>
              <Typography variant="h6" color="text.secondary">{`Total: ${total}`}</Typography>
            </Box>
          </Box>
          <Suspense fallback={<DomainsListSkeleton />}>
            <DomainsList sorting={sorting} pagination={pagination} setTotal={setTotal} />
          </Suspense>
        </Paper>
      </Box>
    </>
  );
};

export default DomainsPage;
