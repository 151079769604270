import { AppBar, Toolbar, Box, IconButton, styled, useTheme, useMediaQuery, ThemeProvider, type Theme } from '@mui/material';
import { Menu as BurgerIcon, Close as CloseIcon } from '@mui/icons-material';
import type { FC } from 'react';
import { darkSubTheme } from 'theme';

const NavbarRoot = styled(AppBar)(
  ({ theme }: { theme: Theme }) => `
  ${theme.breakpoints.up('md')} {
    background-color: ${theme.palette.background.paper};
    padding-left: ${theme.sizes.sidebarWidth}px;
  }
`
);

interface NavbarSkeletonProps {
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const NavbarSkeleton: FC<NavbarSkeletonProps> = ({ isDrawerOpen, openDrawer, closeDrawer }) => {
  const defaultTheme = useTheme();
  const isMobile = useMediaQuery(defaultTheme.breakpoints.down('md'));

  return isMobile ? (
    <ThemeProvider theme={darkSubTheme}>
      <NavbarRoot position="fixed" elevation={0}>
        <Toolbar>
          <Box
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                display: 'block',
              },
              display: 'none',
            })}
          >
            {isDrawerOpen ? (
              <IconButton key="drawer_opener" onClick={closeDrawer}>
                <CloseIcon />
              </IconButton>
            ) : (
              <IconButton key="drawer_opener" onClick={openDrawer}>
                <BurgerIcon />
              </IconButton>
            )}
          </Box>
        </Toolbar>
      </NavbarRoot>
    </ThemeProvider>
  ) : null;
};

export default NavbarSkeleton;
