import { useSuspenseQuery } from '@suspensive/react-query';
import type { QueryKey } from '@tanstack/react-query';
import type { ListResponse, PaginationParams, SortingParams } from 'lib/api';
import { apiClient } from 'lib/api';
import type { QueryConfig } from 'lib/react-query';
import queryString from 'query-string';
import type { CompetitorsFlowsDTO, FilteringFields } from './types';
import getCompetitorsFlowsFromDTO from './getCompetitorsFlowsFromDTO';
import type { CompetitorDomain } from '../types';

export interface GetCompetitorsFlowsOptions {
  params: PaginationParams & Partial<SortingParams> & Partial<FilteringFields>;
}

export const getCompetitorsFlows = async (
  { params }: GetCompetitorsFlowsOptions,
  signal?: AbortSignal
): Promise<ListResponse<CompetitorDomain>> => {
  const res = await apiClient.get<ListResponse<CompetitorsFlowsDTO>>(`/flows`, {
    params,
    signal,
    paramsSerializer: (prms) => queryString.stringify(prms, { arrayFormat: 'comma' }),
  });
  return {
    count: res.data.count,
    limit: res.data.limit,
    offset: res.data.offset,
    data: res.data.data.map((flows) => getCompetitorsFlowsFromDTO(flows)),
  };
};

interface UseCompetitorsFlowsOptions {
  config?: QueryConfig<typeof getCompetitorsFlows>;
  params: GetCompetitorsFlowsOptions['params'];
}

export const useCompetitorsFlows = ({ config, params }: UseCompetitorsFlowsOptions) => {
  const { order_by, offset, limit, domain_id, desc, period } = params;

  return useSuspenseQuery({
    staleTime: 60 * 30 * 1000,
    ...config,
    queryFn: ({ signal }) => getCompetitorsFlows({ params }, signal),
    queryKey: ['flows', offset, limit, domain_id, order_by, desc, period] as QueryKey,
  });
};
