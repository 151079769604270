import type { TextFieldProps, AutocompleteValue } from '@mui/material';
import { useDomains } from '../api';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';
import { sortBy } from 'lodash-es';

interface DomainAutocompleteProps {
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
}

const DomainAutocomplete = ({ InputProps, onChange, value, ...props }: DomainAutocompleteProps): JSX.Element => {
  const { data: domains, isLoading } = useDomains({
    config: {
      select: (data): Option[] => {
        const options = data.map((d) => ({ value: d.id, label: d.domain }));
        return sortBy(options, (option) => option.label);
      },
    },
  });

  return (
    <CheckboxAutocomplete
      {...props}
      id="domain-autocomplete"
      options={domains}
      value={value}
      onChange={onChange as (value: OptionValue[]) => void}
      isLoading={isLoading}
      InputProps={InputProps}
    />
  );
};

export default DomainAutocomplete;
