/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { type FC } from 'react';
import TagAdd from './TagAdd';
import TagDelete from './TagDelete';
import TagSorting from './TagSorting';
import type { TagEntry } from '../../types';
import { useTagsSort } from 'features/tags';

interface TagGroupTagsProps {
  groupId: number,
  tags: TagEntry[]
}

const TagGroupTags: FC<TagGroupTagsProps> = ({ groupId, tags }) => {
  const { currentSorting, sortedTags, setSortOrder } = useTagsSort(tags, {order: 'asc', type: 'alphabet'});

  return (
    <TableContainer
      component={Box}
      sx={t => ({
        borderRadius: 2,
        border: `1px solid ${t.palette.grey[300]}`,
      })}>
      <Table
        size="small"
        stickyHeader
        sx={{
          '& thead th': { backgroundColor: 'white' },
          '& tbody tr:last-child td': { border: 'none' }
        }}>
        <TableHead>
          <TableRow>
            <TableCell sx={{ width: 280 }}>Tag name</TableCell>
            <TableCell>Creatives count</TableCell>
            <TableCell align="right">Sorting <TagSorting setSortOrder={setSortOrder} currentSorting={currentSorting} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TagAdd groupId={groupId} />
          {sortedTags.map(tag => (
            <TableRow
              hover
              sx={{
                '& td:last-child button': { visibility: 'hidden' },
                '&:hover td:last-child button': { visibility: 'visible' }
              }}
              key={tag.tag_id}>
              <TableCell sx={{ width: 280, maxWidth: 280 }}>
                <Typography sx={{ fontSize: 14, wordBreak: 'break-all' }}>{tag.tag_name}</Typography>
              </TableCell>
              <TableCell>{tag.creatives_count}</TableCell>
              <TableCell align="right">
                <TagDelete groupId={groupId} tag={tag} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
};

export default TagGroupTags;
