import { ContentCopy } from "@mui/icons-material";
import type { ButtonProps, Theme} from "@mui/material";
import { Box, Button, ButtonBase, styled } from "@mui/material";
import { transparentize } from "polished";
import type { AdType } from "../types";

export const MediaContainer = styled(Box)({ maxHeight: '80%', width: 560, height: 560 }) as typeof Box;

export const PreviewContainer = styled(Box)<{ img: string }>(({ img, theme }: { img: string; theme: Theme }) => ({
  marginTop: theme.spacing(2),
  aspectRatio: '1',
  background: `url('${img}')`,
  backgroundSize: 'cover',
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  maxWidth: 230,
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    inset: 0,
    backdropFilter: 'blur(10px)',
  },
}));

export const PreviewImage = styled('img')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const PreviewIframe = styled('iframe')({
  objectFit: 'contain',
  width: '100%',
  height: '100%',
  position: 'relative',
});

export const PreviewActionsArea = styled(Box)(({ theme }: { theme: Theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  display: 'flex',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
  zIndex: 2,
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
}));

export const PreviewActionButton = styled((props: ButtonProps) => (
  <Button color="info" size="small" variant="outlined" endIcon={<ContentCopy color="inherit" />} {...props} />
))(({ theme }: { theme: Theme }) => ({
  background: transparentize(0.1, theme.palette.background.paper),
  '&:hover': {
    background: theme.palette.background.paper,
  },
}));

export const PreviewOpenResourceButton = styled(ButtonBase)({
  position: 'absolute',
  inset: 0,
  zIndex: 1,
});

export const AdTypeToLabel: Record<AdType, string> = {
  carousel: 'Carousel',
  html: 'HTML',
  image: 'Image',
  video: 'Video',
};