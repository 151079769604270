import { Grid, Paper, Table, TableBody, TableContainer } from '@mui/material';
import { isEmpty } from 'lodash-es';
import type { FC } from 'react';
import { useEffect } from 'react';
import { useCompetitorsFlows } from '../api';
import type { CompetitorsFlowsOrderValue } from '../types';
import CompetitorsFlowsEmpty from './CompetitorsFlowsEmpty';
import CompetitorsFlowsTableHead from './CompetitorsFlowsTableHead';
import CompetitorsFlowsTableRow from './CompetitorsFlowsTableRow';

interface CompetitorsFlowsListProps {
  limit: number;
  offset: number;
  domains?: number[];
  period: string;
  orderBy: CompetitorsFlowsOrderValue;
  desc: boolean;
  setTotal: (total: number) => void;
  handleToggleSort: (id: CompetitorsFlowsOrderValue) => void;
  reset: () => void;
}

const CompetitorsFlowsList: FC<CompetitorsFlowsListProps> = ({
  limit,
  offset,
  domains,
  reset,
  setTotal,
  orderBy,
  desc,
  handleToggleSort,
  period,
}) => {
  const {
    data: { data: competitorsFlows, count },
  } = useCompetitorsFlows({
    config: {
      enabled: !!orderBy,
    },
    params: { limit, offset, domain_id: domains, order_by: orderBy, desc, period },
  });

  useEffect(() => {
    setTotal(count);
  }, [setTotal, count]);

  return (
    <Grid container>
      {!isEmpty(competitorsFlows) ? (
        <TableContainer sx={{ overflow: 'scroll' }} component={Paper}>
          <Table>
            <CompetitorsFlowsTableHead desc={desc} orderBy={orderBy} handleToggleSort={handleToggleSort} />
            <TableBody>
              {competitorsFlows.map((row) => (
                <CompetitorsFlowsTableRow key={`${row.domain}-${orderBy}-${desc}`} row={row} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <CompetitorsFlowsEmpty reset={reset} />
      )}
    </Grid>
  );
};

export default CompetitorsFlowsList;
