import { useMutation, useQueryClient } from '@tanstack/react-query';
import { apiClient } from 'lib/api';
import type { MutationConfig } from 'lib/react-query';
import type { TagEntry } from '../types';

interface DeleteTagPayload {
  group_id: number;
  tag_id: number;
}

export const deleteTag = async ({group_id, tag_id}: DeleteTagPayload): Promise<TagEntry> => {
  const res = await apiClient.delete<TagEntry>(`/tag_groups/${group_id}/tags/${tag_id}`);
  return res.data;
};

type MutationFnType = (data: DeleteTagPayload) => ReturnType<typeof deleteTag>;

interface UseDeleteTagOptions {
  config?: MutationConfig<MutationFnType>;
}

export const useDeleteTag = (groupId: number, {config}: UseDeleteTagOptions) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    ...config,
    onSuccess: async (...args) => {
      await queryClient.invalidateQueries(['tag_groups']);

      if (config?.onSuccess) {
        await config.onSuccess(...args);
      }
    },
    mutationFn: ({ tag_id }) => deleteTag({group_id: groupId, tag_id}),
  });

  return {
    ...mutation,
    mutate: ({ tag_id }: Pick<DeleteTagPayload, 'tag_id'>) => {
      mutation.mutate({ group_id: groupId, tag_id });
    },
  };
};
