import type { CompetitorFlow, CompetitorDomain } from '../types';
import type { CompetitorFlowDTO, CompetitorsFlowsDTO } from './types';

const getCompetitorsFlowsFromDTO = (dto: CompetitorsFlowsDTO): CompetitorDomain => {
  const parsed = dto.flows.map<CompetitorFlow>((flow: CompetitorFlowDTO) => ({
    ...flow,
    first_seen: new Date(flow.first_seen),
  }));

  return {
    ...dto,
    flows: parsed,
  };
};

export default getCompetitorsFlowsFromDTO;
