import type { Domain } from '../types';
import type { DomainDTO } from './types';

export default function getDomainFromDTO(dto: DomainDTO): Domain {
  const domain: Domain = {
    ...dto,
    created_at: new Date(dto.created_at),
    updated_at: new Date(dto.updated_at),
  };

  return domain;
}
