import type { FC } from 'react';
import type { SelectProps } from '@mui/material';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const IMPRESSIONS_PERIOD_OPTS = [
  { value: '7', label: 'Last week' },
  { value: '30', label: 'Last month' },
  { value: '90', label: 'Last quarter' },
  { value: 'lifetime', label: 'Lifetime' },
];

interface PeriodFilterProps extends SelectProps<string> {
  inputLabel?: string;
}
const PeriodFilter: FC<PeriodFilterProps> = ({ value, onChange, inputLabel, ...restProps }) => (
  <FormControl fullWidth>
    {inputLabel && <InputLabel id="periodLabel">{inputLabel}</InputLabel>}
    <Select labelId="impressionsPeriod" label="Impressions period" value={value} onChange={onChange} {...restProps}>
      {IMPRESSIONS_PERIOD_OPTS.map(({ value: optValue, label }) => (
        <MenuItem key={optValue} value={optValue}>
          {label}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

export default PeriodFilter;
