import { useCallback } from 'react';
import { useQueryState } from './useQueryState';

export type SortingDirection = 'asc' | 'desc';

export interface UseSortingResult<T> {
  field: string;
  order: SortingDirection;
  createSortHandler: (nextField: keyof T) => () => void;
}

export function useSorting<T>(defaultField: keyof T, defaultOrder: SortingDirection = 'asc'): UseSortingResult<T> {
  const [field, setField] = useQueryState('field', String(defaultField));
  const [order, setOrder] = useQueryState('order', String(defaultOrder));

  const createSortHandler = useCallback(
    (nextField: keyof T) => () => {
      setField(String(nextField));
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrder('asc');
      }
    },
    [order, setField, setOrder]
  );

  return { field, order: order as SortingDirection, createSortHandler };
}
