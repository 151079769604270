import type { AutocompleteValue, TextFieldProps } from '@mui/material';
import type { CreativeChannelType } from '../types';
import { CreativeChannel } from '../types';
import type { OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';

interface Channel {
  value: CreativeChannelType;
  label: string;
}
export const CHANNELS = Object.entries(CreativeChannel).map(
  ([value, label]) => ({ value: Number(value), label }) as Channel
);

interface ChannelsAutocompleteProps {
  value?: AutocompleteValue<number, true, false, false>;
  onChange: (value: AutocompleteValue<number, true, false, false>) => void;
  InputProps?: TextFieldProps;
}

const ChannelsAutocomplete = ({ InputProps, value, onChange, ...props }: ChannelsAutocompleteProps): JSX.Element => {
  return (
    <CheckboxAutocomplete
      {...props}
      value={value}
      id="channels-autocomplete"
      onChange={onChange as (value: OptionValue[]) => void}
      InputProps={InputProps}
      options={CHANNELS}
    />
  );
};

export default ChannelsAutocomplete;
