import type { AutocompleteValue, TextFieldProps } from '@mui/material';
import type { AdType } from '../types';
import type { OptionValue } from 'components/common/CheckboxAutocomplete';
import CheckboxAutocomplete from 'components/common/CheckboxAutocomplete';

const TypeMap: Record<AdType, string> = {
  image: 'Image',
  video: 'Video',
  html: 'HTML',
  carousel: 'Carousel',
};

export const types: AdType[] = ['image', 'video', 'html', 'carousel'];
export const AD_TYPES_OPTIONS = types.map((type) => ({ value: type, label: TypeMap[type] }));

interface TypesAutocompleteProps {
  value?: AutocompleteValue<AdType, true, false, false>;
  onChange: (value: AutocompleteValue<AdType, true, false, false>) => void;
  InputProps?: TextFieldProps;
}

const TypesAutocomplete = ({ InputProps, value, onChange, ...props }: TypesAutocompleteProps): JSX.Element => (
  <CheckboxAutocomplete
    {...props}
    id="types-autocomplete"
    options={AD_TYPES_OPTIONS}
    InputProps={InputProps}
    value={value}
    onChange={onChange as (value: OptionValue[]) => void}
  />
);

export default TypesAutocomplete;
