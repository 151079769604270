import { Box, Button, Paper, Typography } from '@mui/material';
import type { FC } from 'react';
import type { FallbackProps } from 'react-error-boundary';

const GlobalErrorBoundaryFallback: FC<FallbackProps> = ({ resetErrorBoundary }) => {
  const handleReset = () => {
    resetErrorBoundary();
  };
  return (
    <Box sx={{ p: 4 }}>
      <Paper sx={{ p: 4, display: 'inline-block' }} variant="outlined">
        <Typography variant="h2" component="h1">
          An unexpected error happened :(
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          I hope this won&apos;t happen to you again.
        </Typography>
        <Button variant="contained" size="large" onClick={handleReset} sx={{ mt: 2 }}>
          Reload page
        </Button>
      </Paper>
    </Box>
  );
};

export default GlobalErrorBoundaryFallback;
