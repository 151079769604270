/* eslint-disable react-refresh/only-export-components */
import type { FC } from 'react';
import { memo, useEffect } from 'react';
import { sortBy, isEqual } from 'lodash-es';
import { Button, Grid, Paper, Typography } from '@mui/material';
import { useCreatives } from '../api';
import CreativeGroup from './CreativeGroup.tsx';

interface WebCreativesListProps {
  limit: number;
  offset: number;
  orderBy?: string;
  desc?: boolean;
  domains?: number[];
  flows?: number[];
  adType?: string[];
  channels?: number[];
  period?: string;
  setTotal: (total: number) => void;
  reset: () => void;
  firstSeen: boolean;
}

const WebCreativesList: FC<WebCreativesListProps> = ({
  limit,
  offset,
  orderBy,
  desc,
  domains,
  flows,
  adType,
  channels,
  period,
  firstSeen,
  setTotal,
  reset,
}) => {
  const {
    data: { data: creatives, count },
  } = useCreatives({
    params: {
      limit,
      offset,
      order_by: orderBy,
      desc,
      domain_id: domains,
      flow_id: flows,
      ad_type: adType,
      channel: channels,
      period,
      first_seen: firstSeen,
    },
  });

  useEffect(() => {
    setTotal(count);
  }, [setTotal, count]);

  return (
    <Grid container spacing={2}>
      {creatives.length > 0 ? (
        creatives.map((creativeGroup) => (
          <CreativeGroup key={creativeGroup.creative_id} creativeGroup={creativeGroup} />
        ))
      ) : (
        <Grid item xs={12}>
          <Paper sx={{ px: 2, py: 4, display: 'flex', alignItems: 'center' }}>
            <Typography variant="h6">Nothing found for your request 🕵🏻‍♂️</Typography>
            <Button variant="outlined" onClick={reset} sx={{ ml: 2 }}>
              Reset Filters
            </Button>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default memo(WebCreativesList, (prevProps, nextProps) => {
  const res = Object.entries(nextProps).reduce((resulting, [key, value]) => {
    const prop = key as keyof WebCreativesListProps;
    if (Array.isArray(value)) {
      const prevValue = prevProps[prop] as typeof value;
      return resulting && isEqual(sortBy(prevValue), sortBy(value));
    }
    return resulting && prevProps[prop] === value;
  }, true);
  // console.log(prevProps, nextProps, res);
  return res;
});
