import type { QueryParamConfig } from 'serialize-query-params';
import { useQueryParam } from 'use-query-params';
import { useMemo, useState } from 'react';
import { debounce } from 'lodash-es';

interface UseDebouncedQueryParamProps<T> {
  name: string;
  defaultValue?: T;
  debounceTime?: number;
}
export const useDebouncedQueryParam = <T, D>({
  name,
  defaultValue,
  debounceTime = 700,
}: UseDebouncedQueryParamProps<T>) => {
  const [param, setParam] = useQueryParam(name, defaultValue as QueryParamConfig<D>);
  const [actualValue, setActualValue] = useState(param);
  const setDebounced = useMemo(() => debounce(setParam, debounceTime), [debounceTime, setParam]);

  const handleChange = (newValue: D) => {
    setActualValue(newValue);
    setDebounced(newValue);
  };
  return {
    param,
    handleChange,
    actualValue,
  };
};
