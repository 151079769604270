import { Button, Typography } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { StyledShowMore, StyledShowMoreRow } from './CompetitorsFlowsComponents';

interface ShowMoreProps {
  onShowMore: () => void;
  open: boolean;
}

const ShowMore = ({ onShowMore, open }: ShowMoreProps) => (
  <StyledShowMoreRow>
    <StyledShowMore>
      <Button size="small" color="info" onClick={onShowMore}>
        <Typography fontWeight="bold" variant="inherit">
          Show {open ? 'less' : 'more'} flows
        </Typography>
        {!open ? <KeyboardArrowDownIcon color="info" /> : <KeyboardArrowUpIcon color="primary" />}
      </Button>
    </StyledShowMore>
  </StyledShowMoreRow>
);

export default ShowMore;
