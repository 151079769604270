import { Box, Paper } from '@mui/material';
import { Suspense, type FC, useCallback } from 'react';
import TagGroupsList from './TagGroupsList';
import TagGroupsListSkeleton from './TagGroupsListSkeleton';
import TagGroupDetails from './tag-group-details/TagGroupDetails';
import TagGroupDetailsSkeleton from './tag-group-details/TagGroupDetailsSkeleton';
import { NumberParam, useQueryParam, withDefault } from 'use-query-params';

const TagGroups: FC = () => {
    const [tagGroupIdDetails, setTagGroupIdDetails] = useQueryParam('group_id', withDefault(NumberParam, null));
    const handleTagGroupIdDetails = useCallback((value: number | null) => {
      setTagGroupIdDetails(value)
    }, [setTagGroupIdDetails]);

    return (
        <>
            <Paper sx={{ display: 'flex', flexGrow: 1 }}>
                <Box sx={{ position: 'relative', display: 'flex', flexGrow: 1, width: '100%' }}>
                    <Suspense fallback={<TagGroupsListSkeleton />}>
                        <TagGroupsList tagGroupIdDetails={tagGroupIdDetails} setTagGroupIdDetails={handleTagGroupIdDetails} />
                    </Suspense>
                </Box>
                {tagGroupIdDetails &&
                    <Box sx={t => ({ borderLeft: 1, borderColor: t.palette.grey[300], display: 'flex', flexDirection: 'column', alignContent: 'stretch', height: '100%', width: '100%' })}>
                        <Suspense fallback={<TagGroupDetailsSkeleton />}>
                            <TagGroupDetails tagGroupIdDetails={tagGroupIdDetails} setTagGroupIdDetails={handleTagGroupIdDetails} />
                        </Suspense>
                    </Box>}
            </Paper>
        </>
    )
};

export default TagGroups;
