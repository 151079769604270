import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import type { FC } from 'react';
import { useMemo } from 'react';
import { useAPIUsages } from '../api';
import APIUsageTypeChip from './APIUsageTypeChip';
import type { APIUsageEntry, APIUsageType } from '../types';

type APIGroupedByEntry = {
  [key in APIUsageType]: APIUsageEntry[];
} & {
  totals: {
    [key in APIUsageType]: number;
  };
};

type APIGroupedBy = Record<string, APIGroupedByEntry>;
interface APIUsageListProps {
  startDate: Date | null;
  endDate: Date | null;
}

const APIUsageList: FC<APIUsageListProps> = ({ startDate, endDate }) => {
  const { data: usages } = useAPIUsages({
    params: {
      startTime: startDate ? startDate.toISOString().slice(0, 19) : undefined,
      endTime: endDate ? endDate.toISOString().slice(0, 19) : undefined,
    },
  });

  const daysRows = useMemo(
    () =>
      Object.entries(
        usages.reduce<APIGroupedBy>((grouped, entry) => {
          const entryDate = entry.created_at.toISOString().slice(0, 10);
          // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
          if (!grouped[entryDate]) {
            grouped[entryDate] = {
              database_domain: [],
              spreadsheets_domain: [],
              spreadsheets_app_high: [],
              spreadsheets_app_low: [],
              totals: {
                database_domain: 0,
                spreadsheets_domain: 0,
                spreadsheets_app_high: 0,
                spreadsheets_app_low: 0,
              },
            };
          }
          grouped[entryDate][entry.type].push(entry);
          grouped[entryDate].totals[entry.type] = grouped[entryDate][entry.type].reduce(
            (sum, one) => sum + one.api_calls_count,
            0
          );
          return grouped;
        }, {})
      ),
    [usages]
  );

  const totals = useMemo(
    () =>
      daysRows.reduce<{
        [key in APIUsageType]: number;
      }>(
        (tot, [, entry]) => {
          tot.database_domain += entry.totals.database_domain;
          tot.spreadsheets_domain += entry.totals.spreadsheets_domain;
          tot.spreadsheets_app_high += entry.totals.spreadsheets_app_high;
          tot.spreadsheets_app_low += entry.totals.spreadsheets_app_low;
          return tot;
        },
        {
          database_domain: 0,
          spreadsheets_domain: 0,
          spreadsheets_app_high: 0,
          spreadsheets_app_low: 0,
        }
      ),
    [daysRows]
  );

  const total = usages.reduce((sum, entry) => sum + entry.api_calls_count, 0);

  return (
    <TableContainer sx={{ height: 0, flexGrow: 1 }}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              Day
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              <APIUsageTypeChip type="database_domain" />
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              <APIUsageTypeChip type="spreadsheets_domain" />
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              <APIUsageTypeChip type="spreadsheets_app_high" />
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              <APIUsageTypeChip type="spreadsheets_app_low" />
            </TableCell>
            <TableCell component="th" sx={{ backgroundColor: 'white' }}>
              Total
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell component="th">
              <Typography>Total</Typography>
            </TableCell>
            <TableCell component="th">
              <Typography>{totals.database_domain}</Typography>
            </TableCell>
            <TableCell component="th">
              <Typography>{totals.spreadsheets_domain}</Typography>
            </TableCell>
            <TableCell component="th">
              <Typography>{totals.spreadsheets_app_high}</Typography>
            </TableCell>
            <TableCell component="th">
              <Typography>{totals.spreadsheets_app_low}</Typography>
            </TableCell>
            <TableCell component="th">
              <Typography>{total}</Typography>
            </TableCell>
          </TableRow>
          {daysRows.map(([day, entries]) => (
            <TableRow key={`usage_${day}`}>
              <TableCell>{day}</TableCell>
              <TableCell>{entries.totals.database_domain}</TableCell>
              <TableCell>{entries.totals.spreadsheets_domain}</TableCell>
              <TableCell>{entries.totals.spreadsheets_app_high}</TableCell>
              <TableCell>{entries.totals.spreadsheets_app_low}</TableCell>
              <TableCell>
                {entries.totals.database_domain +
                  entries.totals.spreadsheets_domain +
                  entries.totals.spreadsheets_app_high +
                  entries.totals.spreadsheets_app_low}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default APIUsageList;
