/* eslint-disable @typescript-eslint/no-unsafe-argument */
import type { CognitoUser } from '@aws-amplify/auth';
import { LinearProgress } from '@mui/material';
import { Auth, Hub } from 'aws-amplify';
import type { AuthStateType } from 'lib/auth/AuthContext';
import { AuthContext } from 'lib/auth/AuthContext';
import type { FC, ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Location } from 'react-router';
import { useNavigate } from 'react-router';

interface AuthContextProviderProps {
  children: ReactNode;
}

const AuthContextProvider: FC<AuthContextProviderProps> = ({ children }) => {
  const [user, setUser] = useState<CognitoUser | null>(null);
  const [state, setState] = useState<AuthStateType>('unauthenticated');
  const navigate = useNavigate();

  const init = useCallback(async () => {
    try {
      const u = (await Auth.currentAuthenticatedUser()) as CognitoUser;
      setUser(u);
      setState('authenticated');
    } catch (err) {
      console.error(err);
      // @ts-expect-error we can safely ignore lack of 'provider', due to use of documented signature
      await Auth.federatedSignIn({ customState: JSON.stringify(window.location) });
    }
  }, []);

  useEffect(() => {
    init().catch(() => {
      /* ignore */
    });

    const unsubscribe = Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          setUser(data);
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'customOAuthState': {
          try {
            const prevLocation = JSON.parse(data as string) as Location;
            navigate(prevLocation);
          } catch (err) {
            /* ignore */
          }
          break;
        }
        default:
          break;
      }
    });

    return unsubscribe;
  }, [navigate, init]);

  const contextValue = useMemo(
    () => ({
      user,
      state,
    }),
    [user, state]
  );

  if (state === 'unauthenticated') {
    return <LinearProgress />;
  }

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthContextProvider;
