import { CssBaseline, LinearProgress } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import GlobalErrorBoundary from 'GlobalErrorBoundary';
import { Amplify } from 'aws-amplify';
import Layout from 'components/layout/Layout';
import LayoutSkeleton from 'components/layout/LayoutSkeleton';
import config from 'config';
import AuthContextProvider from 'lib/auth/AuthContextProvider';
import { queryClient } from 'lib/react-query';
import { SnackbarProvider } from 'notistack';
import ApiUsagePage from 'pages/settings/api-usage';
import TagsPage from 'pages/settings/tags';
import DomainsPage from 'pages/settings/domains';
import CompetitorsFlowsPage from 'pages/competitors-flows';
import queryString from 'query-string';
import type { FC } from 'react';
import React, { Suspense } from 'react';
import { Navigate, Outlet, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { Titled } from 'react-titled';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import CreativesByDomainPage from './pages/web-creatives';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.REGION,
    userPoolId: config.COGNITO_USER_POOL_ID,
    userPoolWebClientId: config.COGNITO_APP_CLIENT_ID,
    oauth: {
      domain: config.COGNITO_DOMAIN,
      scope: ['email', 'openid'],
      redirectSignIn: config.COGNITO_REDIRECT_SIGN_IN ?? `${window.location.origin}/`,
      redirectSignOut: config.COGNITO_REDIRECT_SIGN_OUT ?? `${window.location.origin}/`,
      responseType: 'code',
    },
  },
});

const Providers: FC = () => (
  <QueryParamProvider
    adapter={ReactRouter6Adapter}
    options={{
      searchStringToObject: (str) => queryString.parse(str, { arrayFormat: 'comma' }),
      objectToSearchString: (input) => queryString.stringify(input, { arrayFormat: 'comma' }),
    }}
  >
    <CssBaseline />
    <AuthContextProvider>
      <QueryClientProvider client={queryClient}>
        <GlobalErrorBoundary>
          <SnackbarProvider
            autoHideDuration={1000}
            maxSnack={5}
            anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            preventDuplicate
          >
            <Suspense
              fallback={
                <LayoutSkeleton>
                  <LinearProgress sx={{ mx: -3 }} />
                </LayoutSkeleton>
              }
            >
              <Layout>
                <Outlet />
              </Layout>
            </Suspense>
          </SnackbarProvider>
        </GlobalErrorBoundary>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </AuthContextProvider>
  </QueryParamProvider>
);

const router = createBrowserRouter([
  {
    element: (
      <Titled title="WellSpy">
        <Providers />
      </Titled>
    ),
    children: [
      {
        index: true,
        element: <Navigate to="/web-creatives" replace />,
      },
      {
        path: '/web-creatives',
        element: <CreativesByDomainPage />,
      },
      {
        path: '/competitors-flows',
        element: <CompetitorsFlowsPage />,
      },
      {
        path: '/settings',
        children: [
          { path: 'tags', element: <TagsPage /> },
          { path: 'domains', element: <DomainsPage /> },
          { path: 'api-usage', element: <ApiUsagePage /> },
        ],
      },
      { path: '*', element: <Navigate to="/" replace /> },
    ],
  },
]);

const App: FC = () => (
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

export default App;
