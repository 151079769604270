import { Box, LinearProgress, Typography } from '@mui/material';
import { TagGroups } from 'features/tags';
import { Suspense, type FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Titled } from 'react-titled';
import { AddTagGroupDialog } from 'features/tags';

const TagsPage: FC = () => (
  <>
    <Titled title={(title) => `Tag groups | ${title}`} />
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, p: 3 }}>
      <Box sx={{ mt: 1, mb: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography variant="h5">Tag groups</Typography>
        <AddTagGroupDialog />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignContent: 'stretch', height: '100%' }}>
        <Suspense fallback={<LinearProgress />}>
          <TagGroups />
        </Suspense>
      </Box>
    </Box>
    <Outlet />
  </>
);

export default TagsPage;
