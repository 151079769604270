import type { FC } from 'react';
import { Button, Grid, Paper, Typography } from '@mui/material';

const CompetitorsFlowsEmpty: FC<{ reset: () => void }> = ({ reset }) => (
  <Grid item xs={12}>
    <Paper sx={{ px: 2, py: 4, display: 'flex', alignItems: 'center' }}>
      <Typography variant="h6">Nothing found for your request 🕵🏻‍♂️</Typography>
      <Button variant="outlined" onClick={reset} sx={{ ml: 2 }}>
        Reset Filters
      </Button>
    </Paper>
  </Grid>
);
export default CompetitorsFlowsEmpty;
