import type { FC } from 'react';
import { useMemo } from 'react';
import type { TextFieldProps } from '@mui/material';
import { Autocomplete, Checkbox, createFilterOptions, TextField, Typography } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import type * as React from 'react';
import type { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import type { AutocompleteChangeReason, AutocompleteValue } from '@mui/base/useAutocomplete/useAutocomplete';

export type OptionValue = number | string;

export interface Option {
  value: OptionValue;
  label: string;
}

interface CheckboxAutocompleteProps {
  value?: OptionValue[];
  onChange: (value: OptionValue[]) => void;
  InputProps?: TextFieldProps;
  id: string;
  isLoading?: boolean;
  options: Option[];
}

const CheckboxAutocomplete: FC<CheckboxAutocompleteProps> = ({
  id,
  options,
  isLoading,
  onChange,
  value,
  InputProps,
  ...props
}: CheckboxAutocompleteProps) => {
  const derivedValue = useMemo(() => options.filter((o) => value?.includes(o.value)), [value, options]);

  const allSelected = options.length === derivedValue.length;

  const handleSelectAll = () => {
    if (allSelected) {
      onChange([]);
    } else {
      onChange(options.filter((o) => o.value !== 'select-all').map((o) => o.value));
    }
  };

  const filter = createFilterOptions<Option>();

  const optionRenderer = (
    renderProps: React.HTMLAttributes<HTMLLIElement>,
    option: Option,
    { selected }: AutocompleteRenderOptionState
  ) => {
    const selectAllProps = option.value === 'select-all' ? { checked: allSelected } : {};
    return (
      <li {...renderProps}>
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon />}
          checkedIcon={<CheckBoxIcon />}
          checked={selected}
          {...selectAllProps}
        />
        {option.label}
      </li>
    );
  };

  const handleChange = (
    event: React.SyntheticEvent,
    newValue: AutocompleteValue<Option, true, false, false>,
    reason: AutocompleteChangeReason
  ) => {
    if (reason === 'selectOption' || reason === 'removeOption') {
      if (newValue.find((option) => option.value === 'select-all')) {
        handleSelectAll();
      } else {
        onChange(newValue.map((o) => o.value));
      }
    } else if (reason === 'clear') {
      onChange([]);
    }
  };

  return (
    <Autocomplete
      {...props}
      multiple
      disabled={isLoading ?? InputProps?.disabled}
      loading={isLoading}
      id={id}
      options={options}
      disableCloseOnSelect
      value={derivedValue}
      getOptionLabel={(option) => option.label}
      renderOption={optionRenderer}
      onChange={handleChange}
      renderInput={(params) => <TextField {...params} label={InputProps?.label} />}
      renderTags={(items: Option[]) => (
        <Typography>
          {InputProps?.label}{' '}
          {items.length !== options.length && (
            <Typography component="span" color={(theme) => theme.palette.error.main}>
              ({items.length})
            </Typography>
          )}
        </Typography>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        return [{ label: 'Select all', value: 'select-all' }, ...filtered];
      }}
    />
  );
};

export default CheckboxAutocomplete;
