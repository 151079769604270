/* eslint-disable react-refresh/only-export-components */
/* eslint-disable  @typescript-eslint/no-non-null-assertion */
import { ContentCopy } from '@mui/icons-material';
import {
  Box,
  Button,
  Chip,
  Dialog,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { differenceInDays, format, formatDistanceToNow } from 'date-fns';
import { useModal } from 'hooks';
import { enqueueSnackbar } from 'notistack';
import type { FC } from 'react';
import type React from 'react';
import { useCallback } from 'react';
import useChangeCreativeComment from '../hooks/useChangeCreativeComment';
import type { Creative, RootChannel } from '../types';
import { CreativeGroup, RootCreativeChannels } from '../types';
import Carousel from './Carousel';
import {
  AdTypeToLabel,
  MediaContainer,
  PreviewActionButton,
  PreviewActionsArea,
  PreviewContainer,
  PreviewIframe,
  PreviewImage,
  PreviewOpenResourceButton,
} from './CreativeComponents';
import { maxBy, min } from 'lodash-es';
interface CreativeItemProps {
  creativeGroup: CreativeGroup;
}

const getDominantRootChannel = (creatives: Creative[]): { channel: RootChannel; count: number } => {
  const channelsCount = creatives.reduce(
    (acc, creative) => {
      if (RootCreativeChannels.meta.includes(creative.channel)) {
        acc.meta += 1;
      } else if (RootCreativeChannels.google.includes(creative.channel)) {
        acc.google += 1;
      }

      return acc;
    },
    { meta: 0, google: 0 }
  );

  return maxBy(
    [
      { channel: 'google', count: channelsCount.google },
      { channel: 'meta', count: channelsCount.meta },
    ],
    'count'
  )!;
};

const CreativeGroup: FC<CreativeItemProps> = ({ creativeGroup }) => {
  const modal = useModal();
  const [creative] = creativeGroup.creative_items;
  const dominantRootChannel = getDominantRootChannel(creativeGroup.creative_items);

  const minCreatedDate = min([...creativeGroup.creative_items.map((item) => item.created_at.getTime())])!;

  const {
    error: commentError,
    comment,
    setComment,
    mutation,
  } = useChangeCreativeComment(creativeGroup.creative_id, creativeGroup.comment);
  const handleCommentChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setComment(event.target.value);
    },
    [setComment]
  );
  const isVideo = creative.ad_type === 'video';
  const isImage = creative.ad_type === 'image';
  const isCarousel = creative.ad_type === 'carousel';
  const isHTML = creative.ad_type === 'html';

  const socialPageURL = `https://fb.com/${creative.page_id}`;

  const handleCopyPreview = useCallback(() => {
    copy(creative.img_link);
    enqueueSnackbar('Preview URL was copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  }, [creative.img_link]);
  const handleCopyResource = useCallback(() => {
    copy(isVideo ? creative.vid_link : creative.img_link);
    enqueueSnackbar('Resource URL was copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  }, [creative.img_link, creative.vid_link, isVideo]);

  const handleCopyLandingURL = useCallback(() => {
    copy(creative.landing_page_link);
    enqueueSnackbar('Landing URL was copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  }, [creative.landing_page_link]);
  const handleCopySocialPageURL = useCallback(() => {
    copy(socialPageURL);
    enqueueSnackbar('Social Page URL was copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  }, [socialPageURL]);
  const handleCopyAdLink = useCallback(() => {
    copy(creative.ad_link);
    enqueueSnackbar('Ad link was copied to clipboard!', {
      variant: 'success',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'left',
      },
    });
  }, [creative.ad_link]);

  return (
    <>
      <Dialog open={modal.isOpen} onClose={modal.close}>
        <Box sx={{ p: 2 }}>
          {isVideo && (
            <MediaContainer component="video" controls muted>
              <source src={creative.vid_link} type="video/mp4" />
            </MediaContainer>
          )}
          {isImage && <MediaContainer component="img" src={creative.img_link} />}
          {isCarousel && <MediaContainer component={Carousel} images={creative.resources} />}
          {isHTML && <MediaContainer component="iframe" sandbox="" src={creative.resources[0]} />}
          <Box sx={{ mt: 2 }}>
            <Button
              color="info"
              variant="outlined"
              endIcon={<ContentCopy color="inherit" />}
              onClick={handleCopyResource}
            >
              Copy URL
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Grid item xs={12}>
        <Paper sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <Box sx={{ display: 'flex', gap: 2 }}>
                {/* <Box>
                  <Typography variant="overline" color="text.secondary" data-id={creative.id}>
                    ID
                  </Typography>
                  <Typography variant="h6" sx={{ lineHeight: 1, textTransform: 'uppercase' }}>
                    {creative.slug_id}
                  </Typography>
                </Box> */}
                <Box>
                  <Typography variant="overline" color="text.secondary">
                    Type
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Chip variant="outlined" color="default" label={AdTypeToLabel[creative.ad_type]} size="small" />
                  </Box>
                </Box>
                <Box>
                  <Typography variant="overline" color="text.secondary" noWrap>
                    Network
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 1 }}>
                    <Chip
                      sx={{ textTransform: 'capitalize' }}
                      variant="outlined"
                      color="default"
                      label={`${dominantRootChannel.channel} ${dominantRootChannel.count}`}
                      size="small"
                    />
                  </Box>
                </Box>
                <Box sx={{ width: 0, flexGrow: 1 }}>
                  <Typography variant="overline" color="text.secondary">
                    Domain
                  </Typography>
                  <Typography variant="body1" noWrap>
                    <strong>{creative.domain.domain}</strong>
                  </Typography>
                </Box>
              </Box>
              <PreviewContainer img={creative.img_link}>
                {isHTML ? (
                  <PreviewIframe sandbox="" src={creative.resources[0]} title={creative.img_link} />
                ) : (
                  <PreviewImage src={creative.img_link} alt="preview" />
                )}
                <PreviewActionsArea>
                  <PreviewActionButton onClick={handleCopyPreview}>Preview</PreviewActionButton>
                  <PreviewActionButton onClick={handleCopyResource}>Video</PreviewActionButton>
                </PreviewActionsArea>
                <PreviewOpenResourceButton onClick={modal.open} />
              </PreviewContainer>
              <Box sx={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', mt: 1 }}>
                {creative.ad_link ? (
                  <>
                    <Typography variant="h6" component="a" href={creative.ad_link} target="_blank" noWrap>
                      {creative.title}
                    </Typography>
                    <Button size="small" endIcon={<ContentCopy />} sx={{ flexShrink: 0 }} onClick={handleCopyAdLink}>
                      Copy Ad link
                    </Button>
                  </>
                ) : (
                  <Typography variant="h6" noWrap>
                    {creative.title}
                  </Typography>
                )}
              </Box>
              <Typography variant="body1">{creative.body}</Typography>
              <Typography variant="body1">{creative.message}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Stack spacing={2}>
                <Stack direction="row" spacing={6}>
                  <Box
                    sx={(theme) => ({
                      minHeight: theme.spacing(10),
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    })}
                  >
                    <Typography variant="overline" color="text.secondary">
                      Impressions
                    </Typography>
                    <Typography variant="h6" textAlign="center" sx={{ lineHeight: 1 }}>{`${(
                      creativeGroup.impressions / 1000
                    ).toFixed(1)}K`}</Typography>
                  </Box>
                  <Box>
                    <Typography variant="overline" color="text.secondary">
                      In Wellspy
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <Box>
                        <Typography variant="h6" sx={{ lineHeight: 1 }}>
                          {`${differenceInDays(new Date(), minCreatedDate)} days`}
                        </Typography>
                        <Typography variant="caption" sx={{ lineHeight: 1 }}>
                          {format(minCreatedDate, 'dd.MM.yyyy')}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Stack>
                <Box>
                  <Typography variant="overline" color="text.secondary">
                    Info
                  </Typography>
                  <Table size="small">
                    <TableBody>
                      {isVideo && (
                        <TableRow>
                          <TableCell component="th" variant="head" scope="row">
                            Video duration
                          </TableCell>
                          <TableCell>{`${creative.video_duration} s`}</TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell component="th" variant="head" scope="row">
                          First seen
                        </TableCell>
                        <TableCell>{`${format(creativeGroup.first_seen, 'dd.MM.yyyy')} (${formatDistanceToNow(
                          creativeGroup.first_seen
                        )})`}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell component="th" variant="head" scope="row">
                          Last seen
                        </TableCell>
                        <TableCell>{`${format(creativeGroup.last_seen, 'dd.MM.yyyy')} (${formatDistanceToNow(
                          creativeGroup.last_seen
                        )})`}</TableCell>
                      </TableRow>
                      {creative.page_id && (
                        <TableRow sx={{ 'td, th': { borderBottom: 'none' } }}>
                          <TableCell component="th" variant="head" scope="row">
                            Social Page
                          </TableCell>
                          <TableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                              <Link href={socialPageURL} target="_blank" variant="body1">
                                {creative.page_name}
                              </Link>
                              <Button
                                color="primary"
                                size="small"
                                endIcon={<ContentCopy color="inherit" />}
                                onClick={handleCopySocialPageURL}
                                sx={{ ml: 'auto' }}
                              >
                                Copy URL
                              </Button>
                            </Box>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  </Table>
                </Box>
                <Box>
                  <TextField
                    fullWidth
                    value={creative.landing_page_link}
                    onChange={() => {
                      /* just non interactive field */
                    }}
                    label="Landing page URL"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleCopyLandingURL}>
                            <ContentCopy />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Box sx={{ mt: 2 }}>
                <TextField
                  multiline
                  minRows={5}
                  fullWidth
                  label="Comment"
                  value={comment}
                  onChange={handleCommentChange}
                  error={Boolean(commentError)}
                  helperText={
                    commentError ??
                    (mutation.isLoading
                      ? `Saving...`
                      : `Saved at: ${format(creativeGroup.updated_at, 'dd.MM.yyyy HH:mm')}`)
                  }
                />
              </Box>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default CreativeGroup;
