import { Typography, Box, Paper, LinearProgress } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import type { FC } from 'react';
import { Suspense, useMemo, useState } from 'react';
import { Titled } from 'react-titled';
import { APIUsageList } from 'features/api-usage';
import { endOfMonth, startOfMonth } from 'date-fns';

const ApiUsagePage: FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState<Date | null>(endOfMonth(new Date()));

  const startDateUTCCorrected = useMemo(
    () => (startDate ? new Date(startDate.getTime() - startDate.getTimezoneOffset() * 60000) : null),
    [startDate]
  );
  const endDateUTCCorrected = useMemo(
    () => (endDate ? new Date(endDate.getTime() - endDate.getTimezoneOffset() * 60000) : null),
    [endDate]
  );

  return (
    <>
      <Titled title={(title) => `API usage | ${title}`} />
      <Box sx={{ p: 3, display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h5">API usage</Typography>
        </Box>
        <Paper sx={{ mt: 3.5, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ p: 2, display: 'flex', gap: 2, alignItems: 'center' }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Box>
                <DatePicker label="Start date" value={startDate} onChange={setStartDate} format="dd-MM-yyyy" />
              </Box>
              <Box>
                <DatePicker label="End date" value={endDate} onChange={setEndDate} format="dd-MM-yyyy" />
              </Box>
            </LocalizationProvider>
          </Box>
          <Suspense fallback={<LinearProgress />}>
            <APIUsageList startDate={startDateUTCCorrected} endDate={endDateUTCCorrected} />
          </Suspense>
        </Paper>
      </Box>
    </>
  );
};

export default ApiUsagePage;
