import { useEffect } from 'react';
import type { UrlUpdateType } from 'use-query-params';
import { StringParam, useQueryParam } from 'use-query-params';
import { useColumnsSettingsContext } from '../providers/ColumnsSettingsContext';
import type { CompetitorsFlowsOrder, CompetitorsFlowsOrderValue, QueryParamsNewValueType } from '../types';

const FLOW_SORT_PRIORITY: CompetitorsFlowsOrder[] = [
  'impressions_total',
  'impressions_meta',
  'impressions_google',
  'creatives',
];

export function useFlowSortingParam(): [
  string | null | undefined,
  (newValue: QueryParamsNewValueType<string | null | undefined>, updateType?: UrlUpdateType) => void,
] {
  const [orderBy, setOrderBy] = useQueryParam('order_by', StringParam);
  const { visibilityColumns, recentlyHiddenColumn } = useColumnsSettingsContext();

  useEffect(() => {
    if (!orderBy || recentlyHiddenColumn === orderBy) {
      const priorityOrder: CompetitorsFlowsOrderValue =
        FLOW_SORT_PRIORITY.find((orderField: CompetitorsFlowsOrder) => visibilityColumns[orderField]) ?? 'flow';
      setOrderBy(priorityOrder);
    }
  }, [orderBy, recentlyHiddenColumn, setOrderBy, visibilityColumns]);

  return [orderBy, setOrderBy];
}
