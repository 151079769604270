import type { FC, ReactNode } from 'react';
import { styled, ThemeProvider, CssBaseline } from '@mui/material';
import { useModal } from 'hooks';
import theme from 'theme';
import SidebarSkeleton from './SidebarSkeleton';
import NavbarSkeleton from './NavbarSkeleton';

const Base = styled('div')`
  display: flex;
  min-height: 100vh;
`;

const Main = styled('main')`
  flex-grow: 1;
  padding: 0 ${({ theme: t }) => t.spacing(3)} ${({ theme: t }) => t.spacing(3)};
  display: flex;
  flex-direction: column;

  ::before {
    content: '';
    display: block;
    ${({ theme: t }) => t.mixins.toolbar}
  }
`;

interface LayoutProps {
  children: ReactNode;
}

const LayoutSkeleton: FC<LayoutProps> = ({ children }) => {
  const { isOpen: isDrawerOpen, open: openDrawer, close: closeDrawer } = useModal();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Base>
        <NavbarSkeleton isDrawerOpen={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
        <SidebarSkeleton isShown={isDrawerOpen} openDrawer={openDrawer} closeDrawer={closeDrawer} />
        <Main>{children}</Main>
      </Base>
    </ThemeProvider>
  );
};
export default LayoutSkeleton;
