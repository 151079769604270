import type { AdType } from '../types';
import { useDomains } from 'features/domains';
import { isEmpty } from 'lodash-es';
import type { Theme } from '@mui/material';
import { Box, Button, useMediaQuery } from '@mui/material';
import { useDeletableChips, useModal } from 'hooks';
import type { Option, OptionValue } from 'components/common/CheckboxAutocomplete';
import { useFlowsAutocomplete } from 'features/competitors-flows';
import { AD_TYPES_OPTIONS, CHANNELS } from 'features/creatives';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface FilterHeaderProps {
  selectedDomains: number[];
  changeDomains: (value: number[]) => void;
  selectedFlows: number[];
  changeFlows: (value: number[]) => void;
  selectedChannels: number[];
  changeChannels: (value: number[]) => void;
  selectedAdTypes: AdType[];
  changeAdTypes: (value: AdType[]) => void;
  handleReset: () => void;
}

const FilterHeader = ({
  selectedDomains,
  changeDomains,
  selectedFlows,
  changeFlows,
  selectedChannels,
  changeChannels,
  selectedAdTypes,
  changeAdTypes,
  handleReset,
}: FilterHeaderProps) => {
  const { isOpen, toggle } = useModal();
  const isSmallerScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('macbook15'));
  const chipsToShow = isSmallerScreen ? 10 : 20;

  const { data: domains } = useDomains<Option[]>({
    config: {
      select: (data) => data.map((d) => ({ value: d.id, label: d.domain })),
    },
  });
  const { chips: domainChips } = useDeletableChips({
    changeFn: changeDomains as (value: OptionValue[]) => void,
    selectedData: selectedDomains,
    data: domains,
  });

  const { data: flows } = useFlowsAutocomplete<Option[]>({
    params: { domain_id: selectedDomains },
    config: {
      suspense: false,
      enabled: !isEmpty(selectedDomains),
      select: (data) => data.map((f) => ({ value: f.id, label: f.flow })),
    },
  });
  const { chips: flowChips } = useDeletableChips({
    changeFn: changeFlows as (value: OptionValue[]) => void,
    selectedData: selectedFlows,
    data: flows ?? [],
  });

  const { chips: channelChips } = useDeletableChips({
    changeFn: changeChannels as (value: OptionValue[]) => void,
    selectedData: selectedChannels,
    data: CHANNELS as Option[],
  });

  const { chips: adTypesChips } = useDeletableChips({
    changeFn: changeAdTypes as (value: OptionValue[]) => void,
    selectedData: selectedAdTypes,
    data: AD_TYPES_OPTIONS as Option[],
  });

  const appliedFilters = [...domainChips, ...flowChips, ...channelChips, ...adTypesChips];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', py: appliedFilters.length ? 1 : 0 }}>
      <Box>{!!appliedFilters.length && <Button onClick={handleReset}>Reset filters</Button>}</Box>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', maxHeight: 220, overflowY: 'scroll' }}>
        {isOpen ? appliedFilters : appliedFilters.slice(0, chipsToShow)}
      </Box>
      <Box>
        {appliedFilters.length > chipsToShow && (
          <Button onClick={toggle}>
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}Show {isOpen ? 'less' : 'more'}
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default FilterHeader;
