/* eslint-disable  @typescript-eslint/no-non-null-assertion */
/* eslint-disable  @typescript-eslint/no-non-null-asserted-optional-chain */
import { Button, CircularProgress, TableCell, TableRow, TextField, Typography } from '@mui/material';
import { ErrorOutline, Add as AddIcon, Close as CloseIcon } from '@mui/icons-material';
import { useState, type FC, useCallback } from 'react';
import { useCreateTag } from '../../api';
import { useModal } from 'hooks';
import { isAxiosError } from 'axios';

interface TagGroupDetailsField {
  groupId: number;
}

const TagAdd: FC<TagGroupDetailsField> = ({ groupId }) => {
  const { toggle: toggleAddTag, isOpen: isAddTag } = useModal(false);
  const [tagName, setTagName] = useState<string>('');
  const [formError, setFormError] = useState<string | null>(null);

  const createTag = useCreateTag(groupId, {
    config: {
      onSuccess: () => {
        setTagName('');
        setFormError(null);
        toggleAddTag();
      },
      onError: (e) => {
        if (isAxiosError(e) && e.response) {
          setFormError((e.response.data as { message: string }).message);
        }
      },
    },
  });

  const handleTagNameChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setTagName(event.target.value);
      setFormError(null);
    },
    [setTagName, setFormError]
  );

  const onChangeTagName = useCallback(() => {
    if (!tagName.length) return;
    setFormError('');
    createTag.mutate({ tag_name: tagName.trim() });
  }, [tagName, createTag]);

  const onClose = () => {
    toggleAddTag();
    setTagName('');
    setFormError(null);
  };

  return (
    <>
      <TableRow>
        <TableCell colSpan={3}>
          <Button
            disabled={isAddTag}
            onClick={toggleAddTag}
            size="small"
            sx={{ textTransform: 'uppercase', px: 0 }}
            variant="text"
            endIcon={<AddIcon />}
          >
            Add tag
          </Button>
        </TableCell>
      </TableRow>
      {isAddTag && (
        <TableRow>
          <TableCell>
            <TextField
              autoFocus
              variant="outlined"
              size="small"
              onKeyUpCapture={(e) => {
                e.key === 'Enter' && onChangeTagName();
              }}
              onBlurCapture={onChangeTagName}
              onChange={handleTagNameChange}
              defaultValue={tagName}
              maxRows={1}
              inputProps={{ style: { fontSize: 14 }, maxLength: 64 }}
              fullWidth
              error={Boolean(formError)}
              helperText={
                formError && (
                  <Typography component="span" variant="caption" marginTop={-2}>
                    {formError}
                  </Typography>
                )
              }
              InputProps={{
                endAdornment: createTag.isLoading ? (
                  <CircularProgress size={20} color="success" />
                ) : createTag.isError && formError?.length ? (
                  <ErrorOutline color="error" sx={{ width: 18 }} />
                ) : null,
              }}
              disabled={createTag.isLoading}
            />
          </TableCell>
          <TableCell>0</TableCell>
          <TableCell align="right">
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={onClose}
              disabled={createTag.isLoading}
              sx={{ minWidth: 34, visibility: 'visible' }}
            >
              <CloseIcon />
            </Button>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default TagAdd;
